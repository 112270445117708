import React, { useState, useEffect } from "react";
import {
  server_post_data,
  update_customer_data_webapp,
  get_my_profile_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleAphabetsChange,
  handleError,
  check_vaild_save,
  combiled_form_data,
  handleSuccess,
  handleEmailChange,
} from "../CommonJquery/CommonJquery.js";
import blank_image from "../Assests/images/dummy.png";
function MyProfile() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [customerdata, setcustomerdata] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    console.log("sdfsfsf");
    setshowLoaderAdmin(true);
    await server_post_data(get_my_profile_data, null)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.data.message);
        } else {
          const data_customer = Response.data.message.data_customer;
          console.log(data_customer);
          if (data_customer.length > 0) {
            console.log(data_customer[0]);
            setcustomerdata(data_customer[0]);
            seteditOldImageData(data_customer[0].user_image);
            setDynaicimage({
              profile_pic_show:
                APL_LINK +
                Response.data.message.data_customer_image +
                data_customer[0].user_image,
            });
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        handleError("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        handleError("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      handleError("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    console.log(vaild_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("old_image_link", editOldImageData);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccess(Response.data.message);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="card card-body account-right">
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <div className="widget">
        <div className="section-header">
          <h5 className="heading-design-h5">My Profile</h5>
        </div>
        <form id="addNewStaff">
          <div className="row user-profile-header">
            {dynaicimage && dynaicimage.profile_pic_show ? (
              <img
                src={dynaicimage.profile_pic_show}
                onError={(e) => (e.target.src = blank_image)}
                alt="Shopup Admin"
              />
            ) : (
              <img src={blank_image} alt="Shopup Admin" />
            )}
            <div className="imgInputCotnainer">
              <p className="drop-img mt-2"> Drop Your Image Here</p>
              <input
                type="file"
                name="profile_pic"
                onChange={handleFileChangedynamic("profile_pic")}
                className=" form-control"
                accept=".jpg,.jpeg,.png"
                hidden
                id="imgInput"
              />
              <span className="condition_error"></span>
              <label htmlFor="imgInput" className="chooseImgBtn">
                {dynaicimage && dynaicimage.profile_pic_show
                  ? "Change Image"
                  : "Choose Image"}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label">
                  Full Name <span className="required">*</span>
                </label>
                <input
                  className="form-control border-form-control trio_mandatory"
                  defaultValue={customerdata.full_name || ""}
                  name="profile_name"
                  id="profile_name"
                  placeholder="Enter Full Name"
                  onInput={handleAphabetsChange}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label">
                  Email Address <span className="required">*</span>
                </label>
                <input
                  className="form-control border-form-control trio_mandatory"
                  defaultValue={customerdata.user_email || ""}
                  name="profile_email"
                  id="profile_email"
                  placeholder="Enter Email Id"
                  onInput={handleEmailChange}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 text-right">
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={() =>
                  handleSaveChangesdynamic(
                    "addNewStaff",
                    update_customer_data_webapp
                  )
                }
              >
                {" "}
                Save Changes{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MyProfile;
