import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  server_post_data,
  get_customer_order_data_webapp,
  customer_order_data,
  update_customer_order_status,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { Modal, Button } from "react-bootstrap";
import {
  formatDateStringdot,
  handleError,
} from "../CommonJquery/CommonJquery.js";

function OrderList() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [RupeesLeft, setRupressLeft] = useState("");
  const [RupeesRight, setRupressRight] = useState("");
  const [productpath, setproductpath] = useState("");
  const [SelectedData, setSelectedData] = useState([]);
  const [orderlist, setorderlist] = useState([]);
  const [DATANOTFOUND, setDATANOTFOUND] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.$(".datatabel").DataTable();
    get_all_front();
  }, []);

  const get_all_front = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_customer_order_data_webapp, null)
      .then(async (Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message);
          const all_orders = Response.data.message.all_orders.length;
          if (all_orders > 0) {
            setorderlist(Response.data.message.all_orders);
            setproductpath(
              APL_LINK + Response.data.message.product_image_route
            );
            setRupressLeft(Response.data.message.rupees_left);
            setRupressRight(Response.data.message.rupess_right);
          }
        }
      })
      .catch((error) => {
        setDATANOTFOUND(true);
        setshowLoaderAdmin(false);
        handleError("Something Went Wrong");
      });
  };

  const openModal = (index) => {
    setSelectedData(orderlist[index]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  console.log(SelectedData);

  return (
    <div className="card card-body account-right">
      <div className="widget">
        {orderlist && orderlist.length > 0 && (
          <>
            <div className="section-header">
              <h5 className="heading-design-h5">Order List</h5>
            </div>
            <div className="order-list-tabel-main table-responsive">
              <div
                id="DataTables_Table_0_wrapper"
                className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
              >
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      className="datatabel table table-striped table-bordered order-list-tabel dataTable no-footer"
                      width="100%"
                      cellSpacing={0}
                      id="DataTables_Table_0"
                      role="grid"
                      aria-describedby="DataTables_Table_0_info"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="DataTables_Table_0"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="Order #: activate to sort column descending"
                            style={{ width: 63 }}
                          >
                            Order #
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_0"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Purchased: activate to sort column ascending"
                            style={{ width: 122 }}
                          >
                            Date Purchased
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_0"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            style={{ width: 54 }}
                          >
                            Status
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_0"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Total: activate to sort column ascending"
                            style={{ width: 57 }}
                          >
                            Total
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_0"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Action: activate to sort column ascending"
                            style={{ width: 54 }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderlist.map((item, index) => (
                          <tr role="row" className="odd" key={index}>
                            <td className="sorting_1">
                              #{item.counter_invoice}
                            </td>
                            <td>{formatDateStringdot(item.entry_date)}</td>
                            <td>
                              {item.order_status === 0 && (
                                <span className="badge badge-warning">
                                  Pending
                                </span>
                              )}
                              {item.order_status === 1 && (
                                <span className="badge badge-info">
                                  In Progress
                                </span>
                              )}
                              {item.order_status === 2 && (
                                <span className="badge badge-success">
                                  Delivered
                                </span>
                              )}
                              {item.order_status === 3 && (
                                <span className="badge badge-danger">
                                  Canceled
                                </span>
                              )}
                            </td>
                            <td>
                              {RupeesLeft} {item.final_amount}
                            </td>
                            <td>
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                href="#"
                                data-original-title="View Detail"
                                className="btn btn-info btn-sm"
                                onClick={() => openModal(index)}
                              >
                                <i className="mdi mdi-eye" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body p-0">
          <div className="success_img d-flex justify-content-center">
            <div className="invoice-box">
              <table cellPadding="0" cellSpacing="0">
                <tr className="top_rw">
                  <td colSpan="2">
                    <h2 style={{ marginBottom: "0px" }}>Tax invoice</h2>
                    <span>
                      Number: {SelectedData.counter_invoice} Date:{" "}
                      {formatDateStringdot(SelectedData.entry_date)}
                    </span>
                  </td>
                </tr>
                {SelectedData.order_type === 1 && (
                  <tr className="top">
                    <td colSpan="3">
                      <table>
                        <tr>
                          <td>
                            <b>Delivery Address:</b>
                            {SelectedData.full_address}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}

                <tr className="information">
                  <td colSpan="3">
                    <table cellSpacing="0" cellPadding="2">
                      <tr className="heading">
                        <td>ITEM</td>
                        <td style={{ textAlign: "center" }}>QTY.</td>
                        <td style={{ textAlign: "right" }}>PRICE (INR)</td>
                      </tr>
                      {SelectedData.order_details &&
                        SelectedData.order_details.map((option, index) => (
                          <tr className="item">
                            <td>{option.product_details[0].product_name}</td>
                            <td style={{ textAlign: "center" }}>
                              {option.product_qty}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {option.product_price_single * option.product_qty}
                            </td>
                          </tr>
                        ))}
                      <tr className="item">
                        <td>
                          <b>Sub Total</b>
                        </td>
                        <td style={{ textAlign: "center" }}></td>
                        <td style={{ textAlign: "right" }}>
                          {RupeesLeft}
                          {SelectedData.product_amount} {RupeesRight}
                        </td>
                      </tr>
                      <tr className="item">
                        <td>
                          <b>Delivery Charge</b>
                        </td>
                        <td style={{ textAlign: "center" }}></td>
                        <td style={{ textAlign: "right" }}>
                          {RupeesLeft}
                          {SelectedData.delivery_amount} {RupeesRight}
                        </td>
                      </tr>
                      <tr className="item">
                        <td>
                          <b>Packing Charge</b>
                        </td>
                        <td style={{ textAlign: "center" }}></td>
                        <td style={{ textAlign: "right" }}>
                          {RupeesLeft}
                          {SelectedData.packing_amount} {RupeesRight}
                        </td>
                      </tr>
                      <tr className="item">
                        <td>
                          <b>Promocode Discount</b>
                        </td>
                        <td style={{ textAlign: "center" }}></td>
                        <td style={{ textAlign: "right" }}>
                          - {RupeesLeft}
                          {SelectedData.promocode_amount} {RupeesRight}
                        </td>
                      </tr>
                      <tr className="item">
                        <td>
                          <b>Grand Total</b>
                        </td>
                        <td style={{ textAlign: "center" }}></td>
                        <td style={{ textAlign: "right" }}>
                          {RupeesLeft}
                          {SelectedData.final_amount} {RupeesRight}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="logoutNoBtn" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default OrderList;
