import React, { useState, useEffect } from "react";
import {
  server_post_data,
  get_my_profile_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { removeData } from "../LocalConnection/LocalConnection";
import Header from "./Header";
import Footer from "./Foter";
import MyProfile from "./MyProfile";
import MyAddress from "./MyAddress";
import WishList from "./WishList";
import { useNavigate } from "react-router-dom";
import OrderList from "./OrderList";
import { handleError } from "../CommonJquery/CommonJquery.js";
import { Modal, Button } from "react-bootstrap";
import blank_image from "../Assests/images/dummy.png";
function MyAccount() {
  const [myProfile, setMyProfile] = useState(true);
  const [address, setAddress] = useState(false);
  const [wishlist, setWishList] = useState(false);
  const [orders, setOrders] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleToogle = (item) => {
    setMyProfile(item === "profile");
    setAddress(item === "address");
    setWishList(item === "wishlist");
    setOrders(item === "orders");
  };

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [customerdata, setcustomerdata] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_my_profile_data, null)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.data.message);
        } else {
          const data_customer = Response.data.message.data_customer;
          if (data_customer.length > 0) {
            console.log(data_customer[0]);
            setcustomerdata(data_customer[0]);
            seteditOldImageData(
              APL_LINK +
                Response.data.message.data_customer_image +
                data_customer[0].user_image
            );
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const navigate = useNavigate();

  const handlelogout = () => {
    setShowModal(true);
  };

  const confirmVIP = () => {
    removeData();
    navigate("/");
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <Header />

      <section className="account-page section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <div className="card account-left">
                    <div className="user-profile-header">
                      {editOldImageData ? (
                        <img
                          alt="logo"
                          onError={(e) => (e.target.src = blank_image)}
                          src={editOldImageData}
                        />
                      ) : (
                        <img alt="logo" src={blank_image} />
                      )}
                      <h5 className="mb-1 text-secondary">
                        <strong>Hi </strong> {customerdata.full_name}
                      </h5>
                      <p> {customerdata.user_moblie_no}</p>
                    </div>
                    <div className="list-group">
                      <div
                        onClick={() => handleToogle("profile")}
                        className={`list-group-item list-group-item-action ${
                          myProfile ? "active" : ""
                        }`}
                      >
                        <i className="mdi mdi-account-outline" /> My Profile
                      </div>
                      <div
                        onClick={() => handleToogle("address")}
                        className={`list-group-item list-group-item-action ${
                          address ? "active" : ""
                        }`}
                      >
                        <i
                          aria-hidden="true"
                          className="mdi mdi-map-marker-circle"
                        />{" "}
                        My Address
                      </div>
                      <div
                        onClick={() => handleToogle("wishlist")}
                        className={`list-group-item list-group-item-action ${
                          wishlist ? "active" : ""
                        }`}
                      >
                        <i
                          aria-hidden="true"
                          className="mdi mdi-heart-outline"
                        />{" "}
                        Wish List{" "}
                      </div>
                      <div
                        onClick={() => handleToogle("orders")}
                        className={`list-group-item list-group-item-action ${
                          orders ? "active" : ""
                        }`}
                      >
                        <i
                          aria-hidden="true"
                          className="mdi mdi-format-list-bulleted"
                        />{" "}
                        Order List
                      </div>
                      <div
                        className="list-group-item list-group-item-action"
                        onClick={() => handlelogout()}
                      >
                        <i aria-hidden="true" className="mdi mdi-lock" /> Logout
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  {myProfile && <MyProfile />}
                  {address && <MyAddress />}
                  {wishlist && <WishList />}
                  {orders && <OrderList />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>Are you sure you want to Logout ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="logoutYesBtn" onClick={() => confirmVIP()}>
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyAccount;
