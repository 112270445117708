import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Foter";
import {
  server_post_data,
  get_home_Banner_category_product_webapp,
  post_home_temp_cart_webapp,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import $ from "jquery";
const Home = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [photos, setphotos] = useState([]);
  const [photosbottom, setphotosbottom] = useState([]);
  const [categories, setcategories] = useState([]);
  const [newproducts, setnewproducts] = useState([]);
  const [freshproducts, setfreshproducts] = useState([]);
  const [trendingproducts, settrendingproducts] = useState([]);
  const [RupressLeft, setRupressLeft] = useState("");
  const [RupressRight, setRupressRight] = useState(0);
  const [bannerpath, setbannerpath] = useState("");
  const [categorypath, setcategorypath] = useState("");
  const [productpath, setproductpath] = useState("");
  const [cartdata, setcartdata] = useState([]);
  const [carouselKey, setCarouselKey] = useState(0);

  const options = {
    items: 1,
    dots: true,
    lazyLoad: true,
    pagination: "true",
    autoplay: 4000,
    loop: true,
    nav: true,
    navText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    rtl: "true",
    stoponhover: "true",
    animateOut: "fadeOut",
  };

  const options5 = {
    items: 2,
    dots: true,
    lazyLoad: true,
    pagination: false,
    autoplay: false,
    loop: false,
    nav: false,
    navText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    rtl: "true",
    stoponhover: "true",
    animateOut: "fadeOut",
  };

  const options2 = {
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 5,
      },
      1000: {
        items: 8,
      },
      1200: {
        items: 8,
      },
    },
    items: 8,
    lazyLoad: true,
    pagination: "true",
    loop: false,
    dots: false,
    autoplay: 2000,
    nav: true,
    navText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    rtl: "true",
    autoplayHoverPause: true,
    stoponhover: "true",
    animateOut: "fadeOut",
  };

  const options3 = {
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
    lazyLoad: true,
    pagination: "true",
    loop: false,
    dots: false,
    autoplay: false,
    nav: true,
    navText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    rtl: "true",
    autoplayHoverPause: true,
  };

  useEffect(() => {
    master_data_get();
  }, []);

  useEffect(() => {
    setCarouselKey((prevKey) => prevKey + 1);
  }, [newproducts, freshproducts, trendingproducts, cartdata]);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(get_home_Banner_category_product_webapp, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setphotos(Response.data.message.banner_top_data);
          setphotosbottom(Response.data.message.banner_bottom_data);
          setcategories(Response.data.message.categary_active_data);
          setcartdata(Response.data.message.temp_cart_data);
          setnewproducts(Response.data.message.final_products_arrive_data);
          setfreshproducts(Response.data.message.final_products_fresh_data);
          settrendingproducts(
            Response.data.message.final_products_Trending_data
          );
          setbannerpath(APL_LINK + Response.data.message.banner_image_route);
          setcategorypath(
            APL_LINK + Response.data.message.category_image_route
          );
          setproductpath(APL_LINK + Response.data.message.product_image_route);
          setRupressLeft(Response.data.message.rupees_left);
          setRupressRight(Response.data.message.rupess_right);
          $("#card_value_add").html(
            Response.data.message.temp_cart_data.length
          );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const add_to_cart = async (product_id_dd, product_qty_dd) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("product_id", product_id_dd);
    fd.append("product_qty", product_qty_dd);
    await server_post_data(post_home_temp_cart_webapp, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get();
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const formatPrice = (price) => {
    return price % 1 === 0 ? `${price}.00` : price.toFixed(2);
  };

  return (
    <>
      <Header />
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      {photos && photos.length > 0 && (
        <section className="carousel-slider-main text-center border-bottom bg-white">
          <OwlCarousel
            key={carouselKey}
            {...options}
            style={{ opacity: 1, display: "block" }}
            className="owl-carousel  owl-theme"
          >
            {photos.map((item, index) => (
              <div className="item text-center" key={index}>
                <img
                  className="img-fluid silder_imag w-auto m-auto"
                  src={`${bannerpath}${item.banner_image}`}
                  alt={item.banner_image}
                />
              </div>
            ))}
          </OwlCarousel>
        </section>
      )}

      {categories && categories.length > 0 && (
        <section className="top-category section-padding">
          <div className="container">
            <OwlCarousel
              key={carouselKey}
              {...options2}
              style={{ opacity: 1, display: "block" }}
              className="owl-carousel owl-theme"
            >
              {categories.map((item, index) => (
                <div className="item" key={index}>
                  <div className="category-item">
                    <Link
                      to={`/catagory/${item.category_name}/${item.primary_id}`}
                    >
                      <img
                        className="img-fluid"
                        src={`${categorypath}${item.category_image}`}
                        alt=""
                      />
                      <h6 className="ellipsis-2-lines">{item.category_name}</h6>
                    </Link>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </section>
      )}
      {newproducts && newproducts.length > 0 && (
        <section className="product-items-slider section-padding">
          <div className="container">
            <div className="section-header">
              <h5 className="heading-design-h5">
                New Arrive
                <a
                  className="float-right text-secondary"
                  href="/catagory/new-arrive"
                >
                  View All
                </a>
              </h5>
            </div>

            <OwlCarousel
              key={carouselKey}
              {...options3}
              style={{ opacity: 1, display: "block" }}
              className="owl-carousel owl-carousel-featured owl-theme"
            >
              {newproducts.map((item, index) => {
                let cartItem = cartdata.find(
                  (data) => item.primary_id === data.product_id
                );
                let count_card = 0;

                // If corresponding data is found in cartdata, update count_card
                if (cartItem) {
                  count_card = cartItem.product_qty;
                }
                return (
                  <div className="item" key={index}>
                    <div className="product">
                      <Link to={`/product_details/${item.primary_id}`}>
                        <div className="product-header">
                          {item.product_disocunt_per > 0 && (
                            <span className="badge badge-success">
                              {item.product_disocunt_per}% OFF
                            </span>
                          )}

                          <img
                            className="img-fluid"
                            src={`${productpath}${item.product_image1}`}
                            alt={item.product_name}
                          />
                        </div>
                        <div className="product-body">
                          <h5 className="ellipsis-2-lines">
                            {item.product_name}
                          </h5>
                          <h6>
                            <strong>
                              <span className="mdi mdi-approval" /> Available in
                            </strong>{" "}
                            - {item.product_qty} {item.product_qty_unit}
                          </h6>
                        </div>
                      </Link>
                      <div className="product-footer">
                        {item.out_of_stock_status > 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm float-right"
                            >
                              <i className="mdi mdi-cart-outline" /> Out Of
                              Stock
                            </button>
                          </>
                        ) : (
                          <>
                            {count_card > 0 ? (
                              <div className="addQuantity">
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "min")
                                  }
                                >
                                  <i className="mdi mdi-minus" />
                                </div>
                                <div>{count_card}</div>
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "plus")
                                  }
                                >
                                  <i className="mdi mdi-plus" />
                                </div>
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm float-right"
                                onClick={() =>
                                  add_to_cart(item.primary_id, "plus")
                                }
                              >
                                <i className="mdi mdi-cart-outline" /> Add To
                                Cart
                              </button>
                            )}
                          </>
                        )}

                        <p className="offer-price mb-0">
                          {parseInt(item.product_disocunt_price) > 0 ? (
                            <>
                              {RupressLeft}
                              {formatPrice(item.product_disocunt_price)}{" "}
                              {RupressRight}
                              <i className="mdi mdi-tag-outline" />
                              <br />
                              <span className="regular-price">
                                {RupressLeft}
                                {formatPrice(item.product_price)}
                                {RupressRight}
                              </span>
                            </>
                          ) : (
                            <div className="mt-31">
                              {RupressLeft}
                              {formatPrice(item.product_price)} {RupressRight}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </section>
      )}
      {trendingproducts && trendingproducts.length > 0 && (
        <section className="product-items-slider section-padding">
          <div className="container">
            <div className="section-header">
              <h5 className="heading-design-h5">
                New Trending
                <a
                  className="float-right text-secondary"
                  href="/catagory/new-trending"
                >
                  View All
                </a>
              </h5>
            </div>

            <OwlCarousel
              key={carouselKey}
              {...options3}
              style={{ opacity: 1, display: "block" }}
              className="owl-carousel owl-carousel-featured owl-theme"
            >
              {trendingproducts.map((item, index) => {
                let cartItem = cartdata.find(
                  (data) => item.primary_id === data.product_id
                );

                // Initialize count_card to 0
                let count_card = 0;

                // If corresponding data is found in cartdata, update count_card
                if (cartItem) {
                  count_card = cartItem.product_qty;
                }
                return (
                  <div className="item" key={index}>
                    <div className="product">
                      <Link to={`/product_details/${item.primary_id}`}>
                        <div className="product-header">
                          {item.product_disocunt_per > 0 && (
                            <span className="badge badge-success">
                              {item.product_disocunt_per}% OFF
                            </span>
                          )}

                          <img
                            className="img-fluid"
                            src={`${productpath}${item.product_image1}`}
                            alt={item.product_name}
                          />
                        </div>
                        <div className="product-body">
                          <h5 className="ellipsis-2-lines">
                            {item.product_name}
                          </h5>
                          <h6>
                            <strong>
                              <span className="mdi mdi-approval" /> Available in
                            </strong>{" "}
                            - {item.product_qty} {item.product_qty_unit}
                          </h6>
                        </div>
                      </Link>
                      <div className="product-footer">
                        {item.out_of_stock_status > 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm float-right"
                            >
                              <i className="mdi mdi-cart-outline" /> Out Of
                              Stock
                            </button>
                          </>
                        ) : (
                          <>
                            {count_card > 0 ? (
                              <div className="addQuantity">
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "min")
                                  }
                                >
                                  <i className="mdi mdi-minus" />
                                </div>
                                <div>{count_card}</div>
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "plus")
                                  }
                                >
                                  <i className="mdi mdi-plus" />
                                </div>
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm float-right"
                                onClick={() =>
                                  add_to_cart(item.primary_id, "plus")
                                }
                              >
                                <i className="mdi mdi-cart-outline" /> Add To
                                Cart
                              </button>
                            )}
                          </>
                        )}
                        <p className="offer-price mb-0">
                          {parseInt(item.product_disocunt_price) > 0 ? (
                            <>
                              {RupressLeft}
                              {formatPrice(item.product_disocunt_price)}{" "}
                              {RupressRight}
                              <i className="mdi mdi-tag-outline" />
                              <br />
                              <span className="regular-price">
                                {RupressLeft}
                                {formatPrice(item.product_price)}
                                {RupressRight}
                              </span>
                            </>
                          ) : (
                            <div className="mt-31">
                              {RupressLeft}
                              {formatPrice(item.product_price)} {RupressRight}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </section>
      )}
      {photosbottom && photosbottom.length > 0 && (
        <section className="carousel-slider-main text-center border-bottom container">
          <OwlCarousel
            key={carouselKey}
            {...options5}
            style={{ opacity: 1, display: "block" }}
            className="owl-carousel carousel-slider-main"
          >
            {photosbottom.map((item, index) => (
              <div className="item" key={index}>
                <img
                  className="img-fluid silder_imag"
                  src={`${bannerpath}${item.banner_image}`}
                  alt={item.banner_image}
                />
              </div>
            ))}
          </OwlCarousel>
        </section>
      )}
      {freshproducts && freshproducts.length > 0 && (
        <section className="product-items-slider section-padding">
          <div className="container">
            <div className="section-header">
              <h5 className="heading-design-h5">
                New Fresh
                <a
                  className="float-right text-secondary"
                  href="/catagory/new-fresh"
                >
                  View All
                </a>
              </h5>
            </div>

            <OwlCarousel
              key={carouselKey}
              {...options3}
              style={{ opacity: 1, display: "block" }}
              className="owl-carousel owl-carousel-featured owl-theme"
            >
              {freshproducts.map((item, index) => {
                let cartItem = cartdata.find(
                  (data) => item.primary_id === data.product_id
                );

                // Initialize count_card to 0
                let count_card = 0;

                // If corresponding data is found in cartdata, update count_card
                if (cartItem) {
                  count_card = cartItem.product_qty;
                }
                return (
                  <div className="item" key={index}>
                    <div className="product">
                      <Link to={`/product_details/${item.primary_id}`}>
                        <div className="product-header">
                          {item.product_disocunt_per > 0 && (
                            <span className="badge badge-success">
                              {item.product_disocunt_per}% OFF
                            </span>
                          )}

                          <img
                            className="img-fluid"
                            src={`${productpath}${item.product_image1}`}
                            alt={item.product_name}
                          />
                        </div>
                        <div className="product-body">
                          <h5 className="ellipsis-2-lines">
                            {item.product_name}
                          </h5>
                          <h6>
                            <strong>
                              <span className="mdi mdi-approval" /> Available in
                            </strong>{" "}
                            - {item.product_qty} {item.product_qty_unit}
                          </h6>
                        </div>
                      </Link>
                      <div className="product-footer">
                        {item.out_of_stock_status > 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm float-right"
                            >
                              <i className="mdi mdi-cart-outline" /> Out Of
                              Stock
                            </button>
                          </>
                        ) : (
                          <>
                            {count_card > 0 ? (
                              <div className="addQuantity">
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "min")
                                  }
                                >
                                  <i className="mdi mdi-minus" />
                                </div>
                                <div>{count_card}</div>
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "plus")
                                  }
                                >
                                  <i className="mdi mdi-plus" />
                                </div>
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm float-right"
                                onClick={() =>
                                  add_to_cart(item.primary_id, "plus")
                                }
                              >
                                <i className="mdi mdi-cart-outline" /> Add To
                                Cart
                              </button>
                            )}
                          </>
                        )}

                        <p className="offer-price mb-0">
                          {parseInt(item.product_disocunt_price) > 0 ? (
                            <>
                              {RupressLeft}
                              {formatPrice(item.product_disocunt_price)}{" "}
                              {RupressRight}
                              <i className="mdi mdi-tag-outline" />
                              <br />
                              <span className="regular-price">
                                {RupressLeft}
                                {formatPrice(item.product_price)}
                                {RupressRight}
                              </span>
                            </>
                          ) : (
                            <div className="mt-31">
                              {RupressLeft}
                              {formatPrice(item.product_price)} {RupressRight}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default Home;
