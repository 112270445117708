import $ from "jquery";
import jQuery from "jquery";
import "select2/dist/js/select2.full";
import "select2/dist/css/select2.css";

// Check if jQuery is available
if (typeof jQuery === "undefined") {
  throw new Error("Owl Carousel requires jQuery");
}

(function ($, window, document) {
  var Carousel = {
    init: function (options, el) {
      var base = this;
      base.$elem = $(el);
      base.options = $.extend(
        {},
        $.fn.owlCarousel.options,
        base.$elem.data(),
        options
      );
      base.userOptions = options;
      base.loadContent();
    },
    loadContent: function () {
      var base = this;
      function getData(data) {
        var content = "";
        if (typeof base.options.jsonSuccess === "function") {
          base.options.jsonSuccess.apply(this, [data]);
        } else {
          for (var i in data.owl) {
            if (data.owl.hasOwnProperty(i)) {
              content += data.owl[i].item;
            }
          }
          base.$elem.html(content);
        }
        base.logIn();
      }

      if (typeof base.options.beforeInit === "function") {
        base.options.beforeInit.apply(this, [base.$elem]);
      }
      if (typeof base.options.jsonPath === "string") {
        $.getJSON(base.options.jsonPath, getData);
      } else {
        base.logIn();
      }
    },
    logIn: function () {
      var base = this;

      base.$elem
        .data("owl-originalStyles", base.$elem.attr("style"))
        .data("owl-originalClasses", base.$elem.attr("class"));
      base.$elem.css({ opacity: 0 });
      base.orignalItems = base.options.items;
      base.checkBrowser();
      base.wrapperWidth = 0;
      base.checkVisible = null;
      base.setVars();
    },
    setVars: function () {
      var base = this;
      if (base.$elem.children().length === 0) {
        return false;
      }
      base.baseClass();
      base.eventTypes();
      base.$userItems = base.$elem.children();
      base.itemsAmount = base.$userItems.length;
      base.wrapItems();
      base.$owlItems = base.$elem.find(".owl-item");
      base.$owlWrapper = base.$elem.find(".owl-wrapper");
      base.playDirection = "next";
      base.prevItem = 0;
      base.prevArr = [0];
      base.currentItem = 0;
      base.customEvents();
      base.onStartup();
    },
    // ... other functions ...
    onStartup: function () {
      var base = this;
      base.updateItems();
      base.calculateAll();
      base.buildControls();
      base.updateControls();
      base.response();
      base.moveEvents();
      base.stopOnHover();
      base.owlStatus();
      if (base.options.transitionStyle !== false) {
        base.transitionTypes(base.options.transitionStyle);
      }
      if (base.options.autoPlay === true) {
        base.options.autoPlay = 5000;
      }
      base.play();
      base.$elem.find(".owl-wrapper").css("display", "block");
      if (base.$elem.is(":visible")) {
        base.$elem.css("opacity", 1);
      } else {
        base.watchVisibility();
      }
      base.onstartup = false;
      base.eachMoveUpdate();
      if (typeof base.options.afterInit === "function") {
        base.options.afterInit.apply(this, [base.$elem]);
      }
    },

    eachMoveUpdate: function () {
      var base = this;
      if (base.options.lazyLoad === true) {
        base.lazyLoad();
      }
      if (base.options.autoHeight === true) {
        base.autoHeight();
      }
      base.onVisibleItems();
      if (typeof base.options.afterAction === "function") {
        base.options.afterAction.apply(this, [base.$elem]);
      }
    },
    updateVars: function () {
      var base = this;
      if (typeof base.options.beforeUpdate === "function") {
        base.options.beforeUpdate.apply(this, [base.$elem]);
      }
      base.watchVisibility();
      base.updateItems();
      base.calculateAll();
      base.updatePosition();
      base.updateControls();
      base.eachMoveUpdate();
      if (typeof base.options.afterUpdate === "function") {
        base.options.afterUpdate.apply(this, [base.$elem]);
      }
    },
    reload: function () {
      var base = this;
      setTimeout(function () {
        base.updateVars();
      }, 0);
    },
    watchVisibility: function () {
      var base = this;
      if (base.$elem.is(":visible") === false) {
        base.$elem.css({ opacity: 0 });
        clearInterval(base.autoPlayInterval);
        clearInterval(base.checkVisible);
        base.checkVisible = setInterval(function () {
          if (base.$elem.is(":visible")) {
            base.reload();
            base.$elem.animate({ opacity: 1 }, 200);
            clearInterval(base.checkVisible);
          }
        }, 500);
      }
      return false;
    },
    wrapItems: function () {
      var base = this;
      base.$userItems
        .wrapAll('<div class="owl-wrapper">')
        .wrap('<div class="owl-item"></div>');
      base.$elem.find(".owl-wrapper").wrap('<div class="owl-wrapper-outer">');
      base.wrapperOuter = base.$elem.find(".owl-wrapper-outer");
      base.$elem.css("display", "block");
    },
    baseClass: function () {
      var base = this;
      var hasBaseClass = base.$elem.hasClass(base.options.baseClass);
      var hasThemeClass = base.$elem.hasClass(base.options.theme);
      if (!hasBaseClass) {
        base.$elem.addClass(base.options.baseClass);
      }
      if (!hasThemeClass) {
        base.$elem.addClass(base.options.theme);
      }
    },
    updateItems: function () {
      var base = this;
      if (base.options.responsive === false) {
        return false;
      }
      if (base.options.singleItem === true) {
        base.options.items = base.orignalItems = 1;
        base.options.itemsCustom = false;
        base.options.itemsDesktop = false;
        base.options.itemsDesktopSmall = false;
        base.options.itemsTablet = false;
        base.options.itemsTabletSmall = false;
        base.options.itemsMobile = false;
        return false;
      }

      var width = $(base.options.responsiveBaseWidth).width();
      if (width > (base.options.itemsDesktop[0] || base.orignalItems)) {
        base.options.items = base.orignalItems;
      }
      if (base.options.itemsCustom !== false) {
        base.options.itemsCustom.sort(function (a, b) {
          return a[0] - b[0];
        });
        for (var i = 0; i < base.options.itemsCustom.length; i++) {
          if (base.options.itemsCustom[i][0] <= width) {
            base.options.items = base.options.itemsCustom[i][1];
          }
        }
      } else {
        if (
          width <= base.options.itemsDesktop[0] &&
          base.options.itemsDesktop !== false
        ) {
          base.options.items = base.options.itemsDesktop[1];
        }
        if (
          width <= base.options.itemsDesktopSmall[0] &&
          base.options.itemsDesktopSmall !== false
        ) {
          base.options.items = base.options.itemsDesktopSmall[1];
        }
        if (
          width <= base.options.itemsTablet[0] &&
          base.options.itemsTablet !== false
        ) {
          base.options.items = base.options.itemsTablet[1];
        }
        if (
          width <= base.options.itemsTabletSmall[0] &&
          base.options.itemsTabletSmall !== false
        ) {
          base.options.items = base.options.itemsTabletSmall[1];
        }
        if (
          width <= base.options.itemsMobile[0] &&
          base.options.itemsMobile !== false
        ) {
          base.options.items = base.options.itemsMobile[1];
        }
      }
      if (
        base.options.items > base.itemsAmount &&
        base.options.itemsScaleUp === true
      ) {
        base.options.items = base.itemsAmount;
      }
    },
    response: function () {
      var base = this;
      if (base.options.responsive !== true) {
        return false;
      }
      var lastWindowWidth = $(window).width();
      base.resizer = function () {
        if ($(window).width() !== lastWindowWidth) {
          if (base.options.autoPlay !== false) {
            clearInterval(base.autoPlayInterval);
          }
          clearTimeout(base.resizeTimer);
          base.resizeTimer = setTimeout(function () {
            lastWindowWidth = $(window).width();
            base.updateVars();
          }, base.options.responsiveRefreshRate);
        }
      };
      $(window).resize(base.resizer);
    },
    updatePosition: function () {
      var base = this;
      base.jumpTo(base.currentItem);
      if (base.options.autoPlay !== false) {
        base.checkAp();
      }
    },
    appendItemsSizes: function () {
      var base = this;
      var roundPages = 0;
      var lastItem = base.itemsAmount - base.options.items;
      base.$owlItems.each(function (index) {
        var $this = $(this);
        $this.css({ width: base.itemWidth }).data("owl-item", Number(index));
        if (index % base.options.items === 0 || index === lastItem) {
          if (!(index > lastItem)) {
            roundPages += 1;
          }
        }
        $this.data("owl-roundPages", roundPages);
      });
    },
    appendWrapperSizes: function () {
      var base = this;
      var width = base.$owlItems.length * base.itemWidth;
      base.$owlWrapper.css({
        width: width * 2,
        left: 0,
      });
      base.appendItemsSizes();
    },
    calculateAll: function () {
      var base = this;
      base.calculateWidth();
      base.appendWrapperSizes();
      base.loops();
      base.max();
    },
    calculateWidth: function () {
      var base = this;
      base.itemWidth = Math.round(base.$elem.width() / base.options.items);
    },
    max: function () {
      var base = this;
      var maximum =
        (base.itemsAmount * base.itemWidth -
          base.options.items * base.itemWidth) *
        -1;
      if (base.options.items > base.itemsAmount) {
        base.maximumItem = 0;
        maximum = 0;
        base.maximumPixels = 0;
      } else {
        base.maximumItem = base.itemsAmount - base.options.items;
        base.maximumPixels = maximum;
      }
      return maximum;
    },
    min: function () {
      return 0;
    },
    loops: function () {
      var base = this;
      base.positionsInArray = [0];
      base.pagesInArray = [];
      var prev = 0;
      var elWidth = 0;

      for (var i = 0; i < base.itemsAmount; i++) {
        elWidth += base.itemWidth;
        base.positionsInArray.push(-elWidth);

        if (base.options.scrollPerPage === true) {
          var item = $(base.$owlItems[i]);
          var roundPageNum = item.data("owl-roundPages");
          if (roundPageNum !== prev) {
            base.pagesInArray[prev] = base.positionsInArray[i];
            prev = roundPageNum;
          }
        }
      }
    },
    buildControls: function () {
      var base = this;
      if (
        base.options.navigation === true ||
        base.options.pagination === true
      ) {
        base.owlControls = $('<div class="owl-controls"/>')
          .toggleClass("clickable", !base.browser.isTouch)
          .appendTo(base.$elem);
      }
      if (base.options.pagination === true) {
        base.buildPagination();
      }
      if (base.options.navigation === true) {
        base.buildButtons();
      }
    },
    buildButtons: function () {
      var base = this;
      var buttonsWrapper = $('<div class="owl-buttons"/>');
      base.owlControls.append(buttonsWrapper);

      base.buttonPrev = $("<div/>", {
        class: "owl-prev",
        html: base.options.navigationText[0] || "",
      });

      base.buttonNext = $("<div/>", {
        class: "owl-next",
        html: base.options.navigationText[1] || "",
      });

      buttonsWrapper.append(base.buttonPrev).append(base.buttonNext);

      buttonsWrapper.on(
        "touchstart.owlControls mousedown.owlControls",
        'div[class^="owl"]',
        function (event) {
          event.preventDefault();
        }
      );

      buttonsWrapper.on(
        "touchend.owlControls mouseup.owlControls",
        'div[class^="owl"]',
        function (event) {
          event.preventDefault();
          if ($(this).hasClass("owl-next")) {
            base.next();
          } else {
            base.prev();
          }
        }
      );
    },
    buildPagination: function () {
      var base = this;
      base.paginationWrapper = $('<div class="owl-pagination"/>');
      base.owlControls.append(base.paginationWrapper);

      base.paginationWrapper.on(
        "touchend.owlControls mouseup.owlControls",
        ".owl-page",
        function (event) {
          event.preventDefault();
          if (Number($(this).data("owl-page")) !== base.currentItem) {
            base.goTo(Number($(this).data("owl-page")), true);
          }
        }
      );
    },
    updatePagination: function () {
      var base = this;
      if (base.options.pagination === false) {
        return false;
      }
      base.paginationWrapper.html("");
      var counter = 0;
      var lastPage = base.itemsAmount - (base.itemsAmount % base.options.items);

      for (var i = 0; i < base.itemsAmount; i++) {
        if (i % base.options.items === 0) {
          counter += 1;
          if (lastPage === i) {
            var lastItem = base.itemsAmount - base.options.items;
          }
          var paginationButton = $("<div/>", {
            class: "owl-page",
          });
          var paginationButtonInner = $("<span></span>", {
            text: base.options.paginationNumbers === true ? counter : "",
            class: base.options.paginationNumbers === true ? "owl-numbers" : "",
          });
          paginationButton.append(paginationButtonInner);
          paginationButton.data("owl-page", lastPage === i ? lastItem : i);
          paginationButton.data("owl-roundPages", counter);

          base.paginationWrapper.append(paginationButton);
        }
      }
      base.checkPagination();
    },
    checkPagination: function () {
      var base = this;
      if (base.options.pagination === false) {
        return false;
      }
      base.paginationWrapper.find(".owl-page").each(function () {
        if (
          $(this).data("owl-roundPages") ===
          $(base.$owlItems[base.currentItem]).data("owl-roundPages")
        ) {
          base.paginationWrapper.find(".owl-page").removeClass("active");
          $(this).addClass("active");
        }
      });
    },
    checkNavigation: function () {
      var base = this;
      if (base.options.navigation === false) {
        return false;
      }
      if (base.options.rewindNav === false) {
        if (base.currentItem === 0 && base.maximumItem === 0) {
          base.buttonPrev.addClass("disabled");
          base.buttonNext.addClass("disabled");
        } else if (base.currentItem === 0 && base.maximumItem !== 0) {
          base.buttonPrev.addClass("disabled");
          base.buttonNext.removeClass("disabled");
        } else if (base.currentItem === base.maximumItem) {
          base.buttonPrev.removeClass("disabled");
          base.buttonNext.addClass("disabled");
        } else if (
          base.currentItem !== 0 &&
          base.currentItem !== base.maximumItem
        ) {
          base.buttonPrev.removeClass("disabled");
          base.buttonNext.removeClass("disabled");
        }
      }
    },
    updateControls: function () {
      var base = this;
      base.updatePagination();
      base.checkNavigation();
      if (base.owlControls) {
        if (base.options.items >= base.itemsAmount) {
          base.owlControls.hide();
        } else {
          base.owlControls.show();
        }
      }
    },
    destroyControls: function () {
      var base = this;
      if (base.owlControls) {
        base.owlControls.remove();
      }
    },
    next: function (speed) {
      var base = this;
      if (base.isTransition) {
        return false;
      }
      base.currentItem +=
        base.options.scrollPerPage === true ? base.options.items : 1;
      if (
        base.currentItem >
        base.maximumItem +
          (base.options.scrollPerPage === true ? base.options.items - 1 : 0)
      ) {
        if (base.options.rewindNav === true) {
          base.currentItem = 0;
          speed = "rewind";
        } else {
          base.currentItem = base.maximumItem;
          return false;
        }
      }
      base.goTo(base.currentItem, speed);
    },
    prev: function (speed) {
      var base = this;
      if (base.isTransition) {
        return false;
      }
      if (
        base.options.scrollPerPage === true &&
        base.currentItem > 0 &&
        base.currentItem < base.options.items
      ) {
        base.currentItem = 0;
      } else {
        base.currentItem -=
          base.options.scrollPerPage === true ? base.options.items : 1;
      }
      if (base.currentItem < 0) {
        if (base.options.rewindNav === true) {
          base.currentItem = base.maximumItem;
          speed = "rewind";
        } else {
          base.currentItem = 0;
          return false;
        }
      }
      base.goTo(base.currentItem, speed);
    },
    goTo: function (position, speed, drag) {
      var base = this;
      if (base.isTransition) {
        return false;
      }
      if (typeof base.options.beforeMove === "function") {
        base.options.beforeMove.apply(this, [base.$elem]);
      }
      if (position >= base.maximumItem) {
        position = base.maximumItem;
      } else if (position <= 0) {
        position = 0;
      }
      base.currentItem = base.owl.currentItem = position;
      if (
        base.options.transitionStyle !== false &&
        drag !== "drag" &&
        base.options.items === 1 &&
        base.browser.support3d === true
      ) {
        base.swapSpeed(0);
        if (base.browser.support3d === true) {
          base.transition3d(base.positionsInArray[position]);
        } else {
          base.css2slide(base.positionsInArray[position], 1);
        }
        base.afterGo();
        base.singleItemTransition();
        return false;
      }
      var goToPixel = base.positionsInArray[position];

      if (base.browser.support3d === true) {
        base.isCss3Finish = false;
        if (speed === true) {
          base.swapSpeed("paginationSpeed");
          window.setTimeout(function () {
            base.isCss3Finish = true;
          }, base.options.paginationSpeed);
        } else if (speed === "rewind") {
          base.swapSpeed(base.options.rewindSpeed);
          window.setTimeout(function () {
            base.isCss3Finish = true;
          }, base.options.rewindSpeed);
        } else {
          base.swapSpeed("slideSpeed");
          window.setTimeout(function () {
            base.isCss3Finish = true;
          }, base.options.slideSpeed);
        }
        base.transition3d(goToPixel);
      } else {
        if (speed === true) {
          base.css2slide(goToPixel, base.options.paginationSpeed);
        } else if (speed === "rewind") {
          base.css2slide(goToPixel, base.options.rewindSpeed);
        } else {
          base.css2slide(goToPixel, base.options.slideSpeed);
        }
      }
      base.afterGo();
    },
    jumpTo: function (position) {
      var base = this;
      if (typeof base.options.beforeMove === "function") {
        base.options.beforeMove.apply(this, [base.$elem]);
      }
      if (position >= base.maximumItem || position === -1) {
        position = base.maximumItem;
      } else if (position <= 0) {
        position = 0;
      }
      base.swapSpeed(0);
      if (base.browser.support3d === true) {
        base.transition3d(base.positionsInArray[position]);
      } else {
        base.css2slide(base.positionsInArray[position], 1);
      }
      base.currentItem = base.owl.currentItem = position;
      base.afterGo();
    },
    afterGo: function () {
      var base = this;
      base.checkPagination();
      base.checkNavigation();
      base.eachMoveUpdate();
      if (typeof base.options.afterMove === "function") {
        base.options.afterMove.apply(this, [base.$elem]);
      }
      if (base.options.autoPlay !== false) {
        base.checkAp();
      }
    },
    stop: function () {
      var base = this;
      base.apStatus = "stop";
      clearInterval(base.autoPlayInterval);
    },
    checkAp: function () {
      var base = this;
      if (base.apStatus !== "stop") {
        base.play();
      }
    },
    play: function () {
      var base = this;
      base.apStatus = "play";
      if (base.options.autoPlay === false) {
        return false;
      }
      clearInterval(base.autoPlayInterval);
      base.autoPlayInterval = setInterval(function () {
        base.next(true);
      }, base.options.autoPlay);
    },
    swapSpeed: function (action) {
      var base = this;
      if (action === "slideSpeed") {
        base.$owlWrapper.css(base.addCssSpeed(base.options.slideSpeed));
      } else if (action === "paginationSpeed") {
        base.$owlWrapper.css(base.addCssSpeed(base.options.paginationSpeed));
      } else if (typeof action !== "string") {
        base.$owlWrapper.css(base.addCssSpeed(action));
      }
    },
    addCssSpeed: function (speed) {
      return {
        "-webkit-transition": "all " + speed + "ms ease",
        "-moz-transition": "all " + speed + "ms ease",
        "-o-transition": "all " + speed + "ms ease",
        transition: "all " + speed + "ms ease",
      };
    },
    removeTransition: function () {
      return {
        "-webkit-transition": "",
        "-moz-transition": "",
        "-o-transition": "",
        transition: "",
      };
    },
    doTranslate: function (pixels) {
      return {
        "-webkit-transform": "translate3d(" + pixels + "px, 0px, 0px)",
        "-moz-transform": "translate3d(" + pixels + "px, 0px, 0px)",
        "-o-transform": "translate3d(" + pixels + "px, 0px, 0px)",
        "-ms-transform": "translate3d(" + pixels + "px, 0px, 0px)",
        transform: "translate3d(" + pixels + "px, 0px, 0px)",
      };
    },
    transition3d: function (value) {
      var base = this;
      base.$owlWrapper.css(base.doTranslate(value));
    },
    css2move: function (value) {
      var base = this;
      base.$owlWrapper.css({ left: value });
    },
    css2slide: function (value, speed) {
      var base = this;
      base.isCssFinish = false;
      base.$owlWrapper.stop(true, true).animate(
        {
          left: value,
        },
        {
          duration: speed || base.options.slideSpeed,
          complete: function () {
            base.isCssFinish = true;
          },
        }
      );
    },
    checkBrowser: function () {
      var base = this;
      var translate3D = "translate3d(0px, 0px, 0px)";
      var tempElem = document.createElement("div");
      tempElem.style.cssText =
        "  -moz-transform:" +
        translate3D +
        "; -ms-transform:" +
        translate3D +
        "; -o-transform:" +
        translate3D +
        "; -webkit-transform:" +
        translate3D +
        "; transform:" +
        translate3D;
      var regex = /translate3d\(0px, 0px, 0px\)/g;
      var asSupport = tempElem.style.cssText.match(regex);
      var support3d = asSupport !== null && asSupport.length === 1;

      var isTouch =
        "ontouchstart" in window || window.navigator.msMaxTouchPoints;

      base.browser = {
        support3d: support3d,
        isTouch: isTouch,
      };
    },
    moveEvents: function () {
      var base = this;
      if (
        base.options.mouseDrag !== false ||
        base.options.touchDrag !== false
      ) {
        base.gestures();
        base.disabledEvents();
      }
    },
    eventTypes: function () {
      var base = this;
      var types = ["s", "e", "x"];
      base.ev_types = {};
      if (base.options.mouseDrag === true && base.options.touchDrag === true) {
        types = ["s", "e", "x"];
      } else if (
        base.options.mouseDrag === false &&
        base.options.touchDrag === true
      ) {
        types = ["t", "e", "x"];
      } else if (
        base.options.mouseDrag === true &&
        base.options.touchDrag === false
      ) {
        types = ["m", "e", "x"];
      }
      base.ev_types.start = types[0] + "touchstart.owl mousedown.owl";
      base.ev_types.move = types[1] + "touchmove.owl mousemove.owl";
      base.ev_types.end = types[2] + "touchend.owl touchcancel.owl mouseup.owl";
    },
    disabledEvents: function () {
      var base = this;
      base.$elem.on("dragstart.owl", function (event) {
        event.preventDefault();
      });
      base.$elem.on("mousedown.disableTextSelect", function (e) {
        return $(e.target).is("input, textarea, select, option");
      });
    },
    gestures: function () {
      var base = this;
      var locals = {
        offsetX: 0,
        offsetY: 0,
        baseElWidth: 0,
        relativePos: 0,
        position: null,
        minSwipe: null,
        maxSwipe: null,
        sliding: null,
        dargging: null,
        targetElement: null,
      };

      base.isCssFinish = true;

      function getTouches(event) {
        if (event.touches) {
          return {
            x: event.touches[0].pageX,
            y: event.touches[0].pageY,
          };
        } else {
          if (event.pageX !== undefined) {
            return {
              x: event.pageX,
              y: event.pageY,
            };
          } else {
            return {
              x: event.clientX,
              y: event.clientY,
            };
          }
        }
      }

      function swapEvents(type) {
        if (type === "on") {
          $(document).on(base.ev_types.move, dragMove);
          $(document).on(base.ev_types.end, dragEnd);
        } else if (type === "off") {
          $(document).off(base.ev_types.move);
          $(document).off(base.ev_types.end);
        }
      }

      function dragStart(event) {
        var ev = event.originalEvent || event || window.event;

        if (ev.which === 3) {
          return false;
        }
        if (base.itemsAmount <= base.options.items) {
          return;
        }
        if (base.isCssFinish === false && !base.options.dragBeforeAnimFinish) {
          return false;
        }
        if (base.isCss3Finish === false && !base.options.dragBeforeAnimFinish) {
          return false;
        }

        if (base.options.autoPlay !== false) {
          clearInterval(base.autoPlayInterval);
        }

        if (
          base.browser.isTouch !== true &&
          !base.$owlWrapper.hasClass("grabbing")
        ) {
          base.$owlWrapper.addClass("grabbing");
        }

        base.newPosX = 0;
        base.newRelativeX = 0;

        $(this).css(base.removeTransition());

        var position = $(this).position();
        locals.relativePos = position.left;

        locals.offsetX = getTouches(ev).x - position.left;
        locals.offsetY = getTouches(ev).y - position.top;

        swapEvents("on");

        locals.sliding = false;
        locals.targetElement = ev.target || ev.srcElement;
      }

      function dragMove(event) {
        var ev = event.originalEvent || event || window.event;
        base.newPosX = getTouches(ev).x - locals.offsetX;
        base.newPosY = getTouches(ev).y - locals.offsetY;
        base.newRelativeX = base.newPosX - locals.relativePos;

        if (
          typeof base.options.startDragging === "function" &&
          locals.dragging !== true &&
          base.newRelativeX !== 0
        ) {
          locals.dragging = true;
          base.options.startDragging.apply(base, [base.$elem]);
        }

        if (
          base.newRelativeX > 8 ||
          (base.newRelativeX < -8 && base.browser.isTouch === true)
        ) {
          if (ev.preventDefault !== undefined) {
            ev.preventDefault();
          } else {
            ev.returnValue = false;
          }
          locals.sliding = true;
        }

        if (
          (base.newPosY > 10 || base.newPosY < -10) &&
          locals.sliding === false
        ) {
          $(document).off("touchmove.owl");
        }

        base.newPosX = Math.max(
          Math.min(base.newPosX, base.newRelativeX / 5),
          base.maximumPixels + base.newRelativeX / 5
        );
        if (base.browser.support3d === true) {
          base.transition3d(base.newPosX);
        } else {
          base.css2move(base.newPosX);
        }
      }

      function dragEnd(event) {
        var ev = event.originalEvent || event || window.event;
        ev.target = ev.target || ev.srcElement;

        locals.dragging = false;

        if (base.browser.isTouch !== true) {
          base.$owlWrapper.removeClass("grabbing");
        }

        if (base.newRelativeX < 0) {
          base.dragDirection = base.owl.dragDirection = "left";
        } else {
          base.dragDirection = base.owl.dragDirection = "right";
        }

        if (base.newRelativeX !== 0) {
          var newPosition = base.getNewPosition();
          base.goTo(newPosition, false, "drag");
          if (
            locals.targetElement === ev.target &&
            base.browser.isTouch !== true
          ) {
            $(ev.target).on("click.disable", function (ev) {
              ev.stopImmediatePropagation();
              ev.stopPropagation();
              ev.preventDefault();
              $(ev.target).off("click.disable");
            });
            var handlers = $._data(ev.target, "events").click;
            var owlStopEvent = handlers.pop();
            handlers.splice(0, 0, owlStopEvent);
          }
        }
        swapEvents("off");
      }
      base.$elem.on(base.ev_types.start, ".owl-wrapper", dragStart);
    },
    getNewPosition: function () {
      var base = this;
      var newPosition = base.closestItem();
      if (newPosition > base.maximumItem) {
        base.currentItem = base.maximumItem;
        newPosition = base.maximumItem;
      } else if (base.newPosX >= 0) {
        newPosition = 0;
        base.currentItem = 0;
      }
      return newPosition;
    },
    closestItem: function () {
      var base = this;
      var array =
        base.options.scrollPerPage === true
          ? base.pagesInArray
          : base.positionsInArray;
      var goal = base.newPosX;
      var closest = null;
      $.each(array, function (i, v) {
        if (
          goal - base.itemWidth / 20 > array[i + 1] &&
          goal - base.itemWidth / 20 < v &&
          base.moveDirection() === "left"
        ) {
          closest = v;
        } else if (
          goal + base.itemWidth / 20 < v &&
          goal + base.itemWidth / 20 >
            (array[i + 1] || array[i] - base.itemWidth) &&
          base.moveDirection() === "right"
        ) {
          closest = array[i + 1] || array[array.length - 1];
        }
      });
      return base.options.scrollPerPage === true
        ? $.inArray(closest, array)
        : $.inArray(closest, base.positionsInArray);
    },
    moveDirection: function () {
      var base = this;
      var direction;
      if (base.newRelativeX < 0) {
        direction = "right";
        base.playDirection = "next";
      } else {
        direction = "left";
        base.playDirection = "prev";
      }
      return direction;
    },
    customEvents: function () {
      var base = this;
      base.$elem.on("owl.next", function () {
        base.next();
      });
      base.$elem.on("owl.prev", function () {
        base.prev();
      });
      base.$elem.on("owl.play", function (event, speed) {
        base.options.autoPlay = speed;
        base.play();
        base.hoverStatus = "play";
      });
      base.$elem.on("owl.stop", function () {
        base.stop();
        base.hoverStatus = "stop";
      });
      base.$elem.on("owl.goTo", function (event, item) {
        base.goTo(item);
      });
      base.$elem.on("owl.jumpTo", function (event, item) {
        base.jumpTo(item);
      });
    },
    stopOnHover: function () {
      var base = this;
      if (
        base.options.stopOnHover === true &&
        base.browser.isTouch !== true &&
        base.options.autoPlay !== false
      ) {
        base.$elem.on("mouseover", function () {
          base.stop();
        });
        base.$elem.on("mouseout", function () {
          if (base.hoverStatus !== "stop") {
            base.play();
          }
        });
      }
    },
    lazyLoad: function () {
      var base = this;
      if (base.options.lazyLoad === false) {
        return false;
      }
      for (var i = 0; i < base.itemsAmount; i++) {
        var $item = $(base.$owlItems[i]);

        if ($item.data("owl-loaded") === "loaded") {
          continue;
        }

        var itemNumber = $item.data("owl-item");
        var $lazyImg = $item.find(".lazyOwl");
        var follow = false;

        if (typeof $lazyImg.data("src") !== "string") {
          $item.data("owl-loaded", "loaded");
          continue;
        }
        if ($item.data("owl-loaded") === undefined) {
          $lazyImg.hide();
          $item.addClass("loading").data("owl-loaded", "checked");
        }
        if (base.options.lazyFollow === true) {
          if (itemNumber >= base.currentItem) {
            follow = true;
          }
        } else {
          follow = true;
        }
        if (follow === true) {
          base.lazyPreload($item, $lazyImg);
        }
      }
    },
    lazyPreload: function ($item, $lazyImg) {
      var base = this;
      var iterations = 0;
      var isBackgroundImg;

      function showImage() {
        $item.data("owl-loaded", "loaded").removeClass("loading");
        $lazyImg.removeAttr("data-src");
        if (base.options.lazyEffect === "fade") {
          $lazyImg.fadeIn(400);
        } else {
          $lazyImg.show();
        }
        if (typeof base.options.afterLazyLoad === "function") {
          base.options.afterLazyLoad.apply(this, [base.$elem]);
        }
      }

      function checkLazyImage() {
        iterations += 1;
        if (base.completeImg($lazyImg.get(0)) || isBackgroundImg === true) {
          showImage();
        } else if (iterations <= 100) {
          window.setTimeout(checkLazyImage, 100);
        } else {
          showImage();
        }
      }

      if ($lazyImg.prop("tagName") === "DIV") {
        $lazyImg.css("background-image", "url(" + $lazyImg.data("src") + ")");
        isBackgroundImg = true;
      } else {
        $lazyImg[0].src = $lazyImg.data("src");
      }
      checkLazyImage();
    },
    autoHeight: function () {
      var base = this;
      var $currentimg = $(base.$owlItems[base.currentItem]).find("img");
      if ($currentimg.get(0) !== undefined) {
        var iterations = 0;

        function addHeight() {
          iterations += 1;
          if (base.completeImg($currentimg.get(0))) {
            var t0 = performance.now();
            var t1 = performance.now();
            var timeTaken = (t1 - t0) / 1000;
            console.log(`Task completed in ${timeTaken.toFixed(4)} seconds`);
            var t2 = performance.now();
            var t3 = performance.now();
            var timeTaken2 = (t3 - t2) / 1000;
            console.log(`Task completed in ${timeTaken2.toFixed(4)} seconds`);
          } else if (iterations <= 100) {
            window.setTimeout(addHeight, 100);
          } else {
            base.wrapperOuter.height(base.$owlWrapper.height() + "px");
          }
        }

        addHeight();
      } else {
        base.wrapperOuter.height(base.$owlWrapper.height() + "px");
      }
    },
    completeImg: function (img) {
      var naturalWidthType;
      if (img.naturalWidth !== undefined) {
        naturalWidthType = img.naturalWidth;
      } else if (img.width !== undefined) {
        naturalWidthType = img.width;
      }
      return naturalWidthType;
    },
    onVisibleItems: function () {
      var base = this;
      if (base.options.addClassActive === true) {
        base.$owlItems.removeClass("active");
      }
      base.visibleItems = [];
      for (
        var i = base.currentItem;
        i < base.currentItem + base.options.items;
        i++
      ) {
        base.visibleItems.push(i);
        if (base.options.addClassActive === true) {
          $(base.$owlItems[i]).addClass("active");
        }
      }
      base.owl.visibleItems = base.visibleItems;
    },
    transitionTypes: function (className) {
      var base = this;
      base.outClass = "owl-" + className + "-out";
      base.inClass = "owl-" + className + "-in";
    },
    singleItemTransition: function () {
      var base = this;
      base.isTransition = true;

      var outClass = base.outClass;
      var inClass = base.inClass;
      var $currentItem = base.$owlItems.eq(base.currentItem);
      var $prevItem = base.$owlItems.eq(base.prevItem);
      var prevPos =
        Math.abs(base.positionsInArray[base.currentItem]) +
        base.positionsInArray[base.prevItem];
      var origin =
        Math.abs(base.positionsInArray[base.currentItem]) + base.itemWidth / 2;

      base.$owlWrapper.addClass("owl-origin").css({
        "-webkit-transform-origin": origin + "px",
        "-moz-perspective-origin": origin + "px",
        "perspective-origin": origin + "px",
      });
      function transStyles(prevPos) {
        return {
          position: "relative",
          left: prevPos + "px",
        };
      }

      $prevItem
        .css(transStyles(prevPos, 10))
        .addClass(outClass)
        .on(base.transitionEnd, function () {
          base.endPrev = true;
          $prevItem.off(base.transitionEnd);
          base.clearTransStyle($prevItem, outClass);
        });

      $currentItem.addClass(inClass).on(base.transitionEnd, function () {
        base.endCurrent = true;
        $currentItem.off(base.transitionEnd);
        base.clearTransStyle($currentItem, inClass);
      });
    },
    clearTransStyle: function (item, className) {
      var base = this;
      item
        .css({
          position: "",
          left: "",
        })
        .removeClass(className);
      if (base.endPrev && base.endCurrent) {
        base.$owlWrapper.removeClass("owl-origin");
        base.endPrev = false;
        base.endCurrent = false;
        base.isTransition = false;
      }
    },
    owlStatus: function () {
      var t = this;
      t.owl = {
        userOptions: t.userOptions,
        baseElement: t.$elem,
        userItems: t.$userItems,
        owlItems: t.$owlItems,
        currentItem: t.currentItem,
        prevItem: t.prevItem,
        visibleItems: t.visibleItems,
        isTouch: t.browser.isTouch,
        browser: t.browser,
        dragDirection: t.dragDirection,
      };
    },
    transitionEndVendor: function () {
      var base = this;
      var vendors = [
        "transitionend",
        "webkitTransitionEnd",
        "transitionend",
        "oTransitionEnd",
      ];
      var endVendor = "";
      for (var i = 0; i < vendors.length; i++) {
        if (vendors[i] in base.$owlWrapper.get(0).style) {
          endVendor = vendors[i];
        }
      }
      return endVendor;
    },
  };

  $.fn.owlCarousel = function (options) {
    return this.each(function () {
      if ($(this).data("owl-init") === true) {
        return false;
      }
      $(this).data("owl-init", true);
      var carousel = Object.create(Carousel);
      carousel.init(options, this);
      $.data(this, "owlCarousel", carousel);
    });
  };

  $.fn.owlCarousel.options = {
    items: 5,
    itemsCustom: false,
    itemsDesktop: [1199, 4],
    itemsDesktopSmall: [979, 3],
    itemsTablet: [768, 2],
    itemsTabletSmall: false,
    itemsMobile: [479, 1],
    singleItem: false,
    itemsScaleUp: false,
    slideSpeed: 200,
    paginationSpeed: 800,
    rewindSpeed: 1000,
    autoPlay: false,
    stopOnHover: false,
    navigation: false,
    navigationText: ["prev", "next"],
    rewindNav: true,
    scrollPerPage: false,
    pagination: "true",
    paginationNumbers: false,
    responsive: true,
    responsiveRefreshRate: 200,
    responsiveBaseWidth: window,
    baseClass: "owl-carousel",
    theme: "owl-theme",
    lazyLoad: false,
    lazyFollow: true,
    lazyEffect: "fade",
    autoHeight: false,
    jsonPath: false,
    jsonSuccess: false,
    dragBeforeAnimFinish: true,
    mouseDrag: true,
    touchDrag: true,
    addClassActive: false,
    transitionStyle: false,
    beforeUpdate: false,
    afterUpdate: false,
    beforeInit: false,
    afterInit: false,
    beforeMove: false,
    afterMove: false,
    afterAction: false,
    startDragging: false,
    afterLazyLoad: false,
  };
})(jQuery, window, document);

$(document).ready(function () {
  // ===========Featured Owl Carousel============
  var objowlcarousel = $(".owl-carousel-featured");
  if (objowlcarousel.length > 0) {
    objowlcarousel.owlCarousel({
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 2,
          nav: false,
        },
        1000: {
          items: 5,
        },
        1200: {
          items: 5,
        },
      },
      lazyLoad: true,
      pagination: "false",
      loop: true,
      dots: false,
      autoPlay: false,
      navigation: true,
      stoponhover: "true",
      rtl: "true",
      nav: true,
      navigationText: [
        "<i class='mdi mdi-chevron-left'></i>",
        "<i class='mdi mdi-chevron-right'></i>",
      ],
    });
  }

  // ===========Category Owl Carousel============
  objowlcarousel = $(".owl-carousel-category");
  if (objowlcarousel.length > 0) {
    objowlcarousel.owlCarousel({
      responsive: {
        0: {
          items: 3,
        },
        600: {
          items: 5,
          nav: false,
        },
        1000: {
          items: 8,
        },
        1200: {
          items: 8,
        },
      },
      items: 8,
      lazyLoad: true,
      pagination: "false",
      loop: true,
      dots: false,
      autoPlay: 2000,
      navigation: true,
      stoponhover: "true",
      rtl: "true",
      nav: true,
      navigationText: [
        "<i class='mdi mdi-chevron-left'></i>",
        "<i class='mdi mdi-chevron-right'></i>",
      ],
    });
  }

  // ===========Right Sidebar============
  $('[data-toggle="offcanvas"]').on("click", function () {
    $("body").toggleClass("toggled");
  });

  // ===========Slider============
  var mainslider = $(".owl-carousel-slider");
  if (mainslider.length > 0) {
    mainslider.owlCarousel({
      items: 1,
      dots: false,
      lazyLoad: true,
      pagination: "true",
      autoPlay: 4000,
      loop: true,
      singleItem: true,
      navigation: true,
      stoponhover: "true",
      rtl: "true",
      nav: true,
      navigationText: [
        "<i class='mdi mdi-chevron-left'></i>",
        "<i class='mdi mdi-chevron-right'></i>",
      ],
    });
  }

  // ===========Select2============
  // $("select").select2();

  // ===========Tooltip============
  // $('[data-toggle="tooltip"]').tooltip();

  // ===========Single Items Slider============
  var sync1 = $("#sync1");
  var sync2 = $("#sync2");
  sync1.owlCarousel({
    singleItem: true,
    items: 1,
    rtl: "true",
    slideSpeed: 1000,
    pagination: "false",
    navigation: true,
    autoPlay: 2500,
    dots: false,
    nav: true,
    navigationText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    afterAction: syncPosition,
    responsiveRefreshRate: 200,
  });
  sync2.owlCarousel({
    items: 5,
    rtl: "true",
    navigation: true,
    dots: false,
    pagination: "false",
    nav: true,
    navigationText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    responsiveRefreshRate: 100,
    afterInit: function (el) {
      el.find(".owl-item").eq(0).addClass("synced");
    },
  });

  function syncPosition(el) {
    var current = this.currentItem;
    $("#sync2")
      .find(".owl-item")
      .removeClass("synced")
      .eq(current)
      .addClass("synced");
    if ($("#sync2").data("owlCarousel") !== undefined) {
      center(current);
    }
  }
  $("#sync2").on("click", ".owl-item", function (e) {
    e.preventDefault();
    var number = $(this).data("owlItem");
    sync1.trigger("owl.goTo", number);
  });

  function center(number) {
    var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
    var num = number;
    var found = false;
    for (var i in sync2visible) {
      if (num === sync2visible[i]) {
        found = true;
      }
    }
    if (found === false) {
      if (num > sync2visible[sync2visible.length - 1]) {
        sync2.trigger("owl.goTo", num - sync2visible.length + 2);
      } else {
        if (num - 1 === -1) {
          num = 0;
        }
        sync2.trigger("owl.goTo", num);
      }
    } else if (num === sync2visible[sync2visible.length - 1]) {
      sync2.trigger("owl.goTo", sync2visible[1]);
    } else if (num === sync2visible[0]) {
      sync2.trigger("owl.goTo", num - 1);
    }
  }
});
