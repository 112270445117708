import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import Sidecart from "./Sidecart";
import { website_logo, website_name } from "../CommonJquery/WebsiteText";
import {
  server_post_data,
  Login_url,
  APL_LINK,
  get_customer_temp_data,
  post_customer_address_data_webapp,
  get_search_product_webapp,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleAlphabetsNumberWithoutSpaceChange,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleIaphabetnumberChange,
  handleLinkClick,
  handleNumbersChange,
  reloadscreen,
} from "../CommonJquery/CommonJquery.js";
import $ from "jquery";
import { storeData, retrieveData } from "../LocalConnection/LocalConnection.js";
let login_flag_res = "0";
let complete_status_one = "0";
let user_otp_get = "0";
let customer_id = "0";
let customer_address_id = "0";
let customer_name = "0";
let customer_mobile_no = "0";
let customer_email = "0";
let customer_image = "0";
function Header() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [CartTeamp, setCartTeamp] = useState([]);
  const [Citytemp, setCitytemp] = useState("");
  const [type_address, setype_address] = useState("Home");
  const [building, setbuilding] = useState("");
  const [Flat, setFlat] = useState("");
  const [user_city, setuser_city] = useState("");
  const [user_state, setuser_state] = useState("");
  const [user_country, setuser_country] = useState("");
  const [user_pincode, setuser_pincode] = useState("");
  const [user_landmark, setuser_landmark] = useState("");
  const [map_address, setmap_address] = useState("");
  const [user_latitude, setuser_latitude] = useState("");
  const [user_longitude, setuser_longitude] = useState("");
  const [primary_id, setprimary_id] = useState("0");
  const [isSearchActive, setisSearchActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [newproducts, setnewproducts] = useState([]);
  const [productpath, setproductpath] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [otpValue, setOtpValue] = useState("");
  useEffect(() => {
    customer_id = retrieveData("customer_id");
    customer_address_id = retrieveData("customer_address_id");
    master_data_get(customer_address_id, 0, 0);
  }, [CartTeamp]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sideBarRef = useRef(null);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    $("body").toggleClass("toggled");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const login_section_res = async () => {
    let vaild = "0";
    let referral_id = $("#reffer_code").val();
    let phone_no = $("#phone_no").val();
    let login_otp = $("#opt_user").val();
    let user_email = $("#user_email").val();
    let user_name = $("#user_name").val();

    if (login_flag_res === "0") {
      if ($.trim(phone_no) === "") {
        vaild = "1";
      } else if ($.trim(phone_no).length < 10 || $.trim(phone_no).length > 10) {
        vaild = "1";
      }
    }

    if (login_flag_res === "1") {
      if ($.trim(login_otp) === "") {
        vaild = "1";
      } else if ($.trim(login_otp) !== user_otp_get) {
        vaild = "1";
      } else {
        if (complete_status_one === "0") {
          $(".otp_section").hide();
          $(".last_section").show();
          $("#register_login").html("COMPLETE PROFILE");
          login_flag_res = "2";
          return;
        } else {
          storeData("customer_id", customer_id);
          storeData("customer_name", customer_name);
          storeData("customer_mobile_no", customer_mobile_no);
          storeData("customer_email", customer_email);
          storeData("customer_image", customer_image);
          window.location.reload();
        }
      }
    }
    if (login_flag_res === "2") {
      if ($.trim(user_name) === "") {
        vaild = "1";
      }
    }

    if (vaild === "0") {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("code_for", "+91");
      fd.append("phone_no", phone_no);
      if (parseInt(login_flag_res) > 0) {
        fd.append("click_type", "1");
      } else {
        fd.append("click_type", login_flag_res);
      }

      fd.append("reffer_code", referral_id);
      fd.append("email_id", user_email);
      fd.append("full_name", user_name);
      await server_post_data(Login_url, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            if (Response.data.message.data_customer.length > 0) {
              user_otp_get =
                Response.data.message.data_customer[0].user_otp + "";
              complete_status_one =
                Response.data.message.data_customer[0].complete_status + "";
              customer_id =
                Response.data.message.data_customer[0].primary_id + "";
              customer_name =
                Response.data.message.data_customer[0].full_name + "";
              customer_mobile_no =
                Response.data.message.data_customer[0].user_moblie_no + "";
              customer_email =
                Response.data.message.data_customer[0].user_email + "";
              customer_image =
                Response.data.message.data_customer_image +
                Response.data.message.data_customer[0].user_image +
                "";

              if (login_flag_res === "0") {
                $("#register_login").html("MATCH OTP");
                $(".hide_ssection_profile").hide();
                $(".otp_section").show();

                login_flag_res = "1";
              } else if (login_flag_res === "2") {
                if (
                  Response.data.message.data_customer[0].complete_status === 0
                ) {
                  $("#register_login").html("COMPLETE PROFILE");
                  $(".otp_section").hide();
                  $(".last_section").show();
                  login_flag_res = "2";
                } else {
                  window.location.reload();
                }
              } else if (login_flag_res === "3") {
                window.location.reload();
              }
            }
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    } else {
      if (login_flag_res === "1") {
        handleError("Enter Vaild Mobile No");
      } else if (login_flag_res === "2") {
        handleError("Enter Vaild OTP");
      } else {
        handleError("Enter Vaild Full name");
      }
    }
  };

  const save_address = async () => {
    let Data = new FormData();
    if (
      user_longitude !== "" &&
      user_city !== "" &&
      Flat !== "" &&
      building !== "" &&
      user_landmark !== ""
    ) {
      setshowLoaderAdmin(true);
      Data.append("user_longitude", user_longitude);
      Data.append("user_latitude", user_latitude);
      Data.append("map_address", map_address);
      Data.append("user_landmark", user_landmark);
      Data.append("user_pincode", user_pincode);
      Data.append("user_country", user_country);
      Data.append("user_state", user_state);
      Data.append("user_city", user_city);
      Data.append("building", building);
      Data.append("flat", Flat);
      Data.append("type_address", type_address);
      Data.append("primary_id", primary_id);
      await server_post_data(post_customer_address_data_webapp, Data)
        .then(async (Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            reloadscreen();
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
          handleError("Something Went Wrong");
        });
    } else {
      handleError("please Select Address");
    }
  };

  useEffect(() => {
    try {
      const input = document.getElementById("searchInput");
      const autocomplete = new window.google.maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", function () {
        const place = autocomplete.getPlace();

        let full_address = place.address_components;
        let formatted_address = place.formatted_address;
        let geometry_data = place.geometry;
        let length_data = place.address_components.length;
        let citys = "";
        let state = "";
        let country = "";
        let tehsil = "";
        let postal_code = "";
        for (let i = 0; i < length_data; i++) {
          if (full_address[i].types[0] === "administrative_area_level_1") {
            state = full_address[i].long_name;
          } else if (full_address[i].types[0] === "country") {
            country = full_address[i].long_name;
          } else if (
            full_address[i].types[0] === "administrative_area_level_2"
          ) {
            citys = full_address[i].long_name;
          } else if (full_address[i].types[0] === "locality") {
            tehsil = full_address[i].long_name;
          } else if (full_address[i].types[0] === "postal_code") {
            postal_code = full_address[i].long_name;
          }
        }
        if (tehsil !== "") {
          citys = tehsil;
        }
        setmap_address(formatted_address);
        setuser_city(citys);
        setuser_state(state);
        setuser_country(country);
        setuser_latitude(geometry_data.location.lng());
        setuser_longitude(geometry_data.location.lat());
        setuser_pincode(postal_code);
        setprimary_id("0");
      });
    } catch (err) {
      //err
    }
  }, []);

  const master_data_get = async (address_id, order_type, promocode_id) => {
    setshowLoaderAdmin(true);
    const Data = new FormData();
    Data.append("address_id", address_id);
    Data.append("order_type", order_type);
    Data.append("promocode_id", promocode_id);
    await server_post_data(get_customer_temp_data, Data)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setCartTeamp(Response.data.message.temp_cart_data.length);
          if (Response.data.message.business_address.length > 0) {
            setCitytemp(
              Response.data.message.business_address[0].business_city
            );
          }
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newTimeout = setTimeout(() => {
      if (searchText.trim().length > 2) {
        get_all_search_data();
      } else {
        setisSearchActive(false);
      }
    }, 500);
    setSearchTimeout(newTimeout);
    return () => {
      if (newTimeout) {
        clearTimeout(newTimeout);
      }
    };
  }, [searchText]);

  const get_all_search_data = async () => {
    let Data = new FormData();
    Data.append("product_category_text", searchText);
    Data.append("normal_fave", "0");
    await server_post_data(get_search_product_webapp, Data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setnewproducts(Response.data.message.product_data);
          setisSearchActive(true);
          setproductpath(APL_LINK + Response.data.message.product_image_route);
        }
      })
      .catch((error) => {
        handleError("Something Went Wrong");
      });
  };

  return (
    <>
      <div className="modal fade login-modal-main" id="bd-example-modal">
        <div className={showLoaderAdmin ? "loading" : ""}></div>
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-modal">
                <div className="row">
                  <div className="col-lg-12 pad-left-0">
                    <button
                      type="button"
                      className="close close-top-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="mdi mdi-close" />
                      </span>
                      <span className="sr-only">Close</span>
                    </button>
                    <form>
                      <div className="login-modal-right">
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="register"
                            role="tabpanel"
                          >
                            <h5 className="heading-design-h5">Login Now!</h5>
                            <fieldset className="form-group hide_ssection_profile">
                              <label>Enter Mobile number</label>
                              <input
                                type="text"
                                className="form-control"
                                id="phone_no"
                                name="phone_no"
                                placeholder="Enter Mobile number"
                                maxLength={10}
                                onInput={handleNumbersChange}
                                onChange={(e) => {
                                  setPhoneValue(e.target.value);
                                }}
                              />
                            </fieldset>
                            <fieldset className="form-group otp_section">
                              <label>Enter OTP</label>
                              <input
                                type="text"
                                className="form-control"
                                id="opt_user"
                                name="opt_user"
                                placeholder="Enter OTP"
                                maxLength={4}
                                onInput={handleNumbersChange}
                                onChange={(e) => {
                                  setOtpValue(e.target.value);
                                }}
                              />
                            </fieldset>
                            <fieldset className="form-group last_section">
                              <label>Full Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                id="user_name"
                                name="user_name"
                                maxLength={100}
                                onInput={handleAphabetsChange}
                              />
                            </fieldset>
                            <fieldset className="form-group last_section">
                              <label>Email ID</label>
                              <input
                                type="text"
                                id="user_email"
                                name="user_email"
                                className="form-control"
                                placeholder="Email ID"
                                maxLength={100}
                                onInput={handleEmailChange}
                              />
                            </fieldset>
                            <fieldset className="form-group last_section">
                              <label>Reffer Code</label>
                              <input
                                type="text"
                                className="form-control"
                                id="reffer_code"
                                name="reffer_code"
                                placeholder="Reffer Code"
                                maxLength={10}
                                onInput={
                                  handleAlphabetsNumberWithoutSpaceChange
                                }
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div className="clearfix" />
                        <div className="text-center login-footer-tab">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a
                                className={`nav-link logInBtn ${
                                  phoneValue.length === 10
                                    ? "logInBtnActive"
                                    : ""
                                } `}
                                data-toggle="tab"
                                href="#login"
                                role="tab"
                                id="register_login"
                                onClick={() => login_section_res()}
                              >
                                <i className="mdi mdi-lock" /> LOGIN
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* nav bar  */}

      <div className="modal fade login-modal-main" id="bd-example-modal2">
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-modal">
                <div className="row">
                  <div className="col-lg-12 pad-left-0">
                    <button
                      type="button"
                      className="close close-top-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="mdi mdi-close" />
                      </span>
                      <span className="sr-only">Close</span>
                    </button>
                    <form>
                      <div className="login-modal-right">
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="register"
                            role="tabpanel"
                          >
                            <h5 className="heading-design-h5">Add Address</h5>
                            <fieldset className="form-group hide_ssection_profile">
                              <label>Flat / House No. / Building Name</label>
                              <input
                                type="text"
                                className="trio_mandatory mb-2 form-control input_field_custom3"
                                id="setFlat"
                                name="setFlat"
                                onChange={(e) => setFlat(e.target.value)}
                                onInput={handleIaphabetnumberChange}
                                placeholder="Enter Building Address"
                              />
                              <label>Area / Sectior / Locality</label>
                              <input
                                type="text"
                                className="trio_mandatory mb-2 form-control input_field_custom3"
                                id="setbuilding"
                                name="setbuilding"
                                onChange={(e) => setbuilding(e.target.value)}
                                onInput={handleIaphabetnumberChange}
                                placeholder="Enter Building Address"
                              />
                              <label>Enter Google Address</label>
                              <input
                                type="text"
                                rows="7"
                                id="searchInput"
                                autoComplete="off"
                                name="admin_manual_adrress"
                                maxLength={250}
                                minLength={5}
                                placeholder="Search Your Address"
                                className="trio_mandatory form-control input_field_custom3"
                              ></input>
                              <label>Landmark</label>
                              <input
                                type="text"
                                className="trio_mandatory form-control input_field_custom3"
                                id="setuser_landmark"
                                onInput={handleIaphabetnumberChange}
                                name="setuser_landmark"
                                onChange={(e) =>
                                  setuser_landmark(e.target.value)
                                }
                                placeholder="Enter Near By Landmark"
                              />
                              <div className="addressType">
                                <button
                                  type="button"
                                  className={`${
                                    type_address === "Home"
                                      ? "selectedADType"
                                      : ""
                                  }`}
                                  onClick={() => setype_address("Home")}
                                >
                                  Home
                                </button>
                                <button
                                  type="button"
                                  className={`${
                                    type_address === "Work"
                                      ? "selectedADType"
                                      : ""
                                  }`}
                                  onClick={() => setype_address("Work")}
                                >
                                  Work
                                </button>
                                <button
                                  type="button"
                                  className={`${
                                    type_address === "Other"
                                      ? "selectedADType"
                                      : ""
                                  }`}
                                  onClick={() => setype_address("Other")}
                                >
                                  Other
                                </button>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="clearfix" />
                        <div className="text-center login-footer-tab">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a
                                className="nav-link active logInBtnActive"
                                data-toggle="tab"
                                href="#login"
                                role="tab"
                                id="register_logi2"
                                onClick={() => save_address()}
                              >
                                <i className="mdi mdi-lock" /> Save Address
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* nav bar  */}

      <div className="headerStiky">
        <nav className="navbar navbar-light navbar-expand-lg bg-dark bg-faded osahan-menu">
          <div className="container-fluid">
            <Link className="navbar-brand" onClick={() => handleLinkClick("/")}>
              {" "}
              <img
                src={website_logo}
                alt={website_name}
                className="width50"
              />{" "}
            </Link>
            <a className="location-top" href="#">
              <i className="mdi mdi-map-marker-circle" aria-hidden="true" />{" "}
              {Citytemp}
            </a>
            <button
              className="navbar-toggler navbar-toggler-white"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="navbar-collapse" id="navbarNavDropdown">
              <div className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main">
                <div className="top-categories-search">
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Search products"
                      aria-label="Search products"
                      type="text"
                      maxLength={30}
                      onInput={handleIaphabetnumberChange}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <span className="input-group-btn">
                      <button className="btn btn-secondary" type="button">
                        <i className="mdi mdi-file-find" /> Search
                      </button>
                    </span>
                    {isSearchActive && (
                      <div className="searchItems">
                        {newproducts.map((item, index) => {
                          return (
                            <Link
                              to={`/product_details/${item.primary_id}`}
                              key={index}
                            >
                              <div className="itemSearch">
                                <img
                                  src={`${productpath}${item.product_image1}`}
                                  alt={item.product_name}
                                />
                                <div>
                                  <h6>{item.product_name}</h6>
                                  <p>
                                    {item.product_qty} {item.product_qty_unit}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                        <div className="itemSearch center_justify">
                          <div>
                            <h6>No Data Found</h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="my-2 my-lg-0">
                <ul className="list-inline main-nav-right">
                  {parseInt(customer_id) === 0 ? (
                    <li className="list-inline-item">
                      <a
                        href="#"
                        data-target="#bd-example-modal"
                        data-toggle="modal"
                        className="btn btn-link"
                      >
                        <i className="mdi mdi-account-circle" /> Login/Sign Up
                      </a>
                    </li>
                  ) : (
                    <li className="list-inline-item">
                      <a href="/my_profile" className="btn btn-link">
                        <i className="mdi mdi-account-circle" /> My Account
                      </a>
                    </li>
                  )}

                  <li className="list-inline-item cart-btn">
                    <div
                      onClick={() => toggleSidebar()}
                      className="btn btn-link border-none"
                      // data-toggle="offcanvas"
                    >
                      <i className="mdi mdi-cart" /> My Cart{" "}
                      <small className="cart-value" id="card_value_add">
                        {CartTeamp}
                      </small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light osahan-menu-2 pad-none-mobile">
          <div className="container-fluid">
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto">
                <li className="nav-item">
                  <Link className="nav-link shop" to="/">
                    <span className="mdi mdi-store" />
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/terms-and-conditions">
                    Terms & Conditions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact_us">
                    Contact Us
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About Us
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* nav bar  */}
      <div className="notification-component" ref={sideBarRef}>
        {isSidebarOpen && (
          <Sidecart
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
          />
        )}
      </div>
    </>
  );
}

export default Header;
