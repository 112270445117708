import React, { useEffect, useState } from "react";
import Header from "./Header";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import shopimg from "../Assests/images/shop.jpg";
import { useParams } from "react-router-dom";
import {
  server_post_data,
  get_category_product_web_webapp,
  post_home_temp_cart_webapp,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery.js";
import Footer from "./Foter";
import { Link } from "react-router-dom";
import $ from "jquery";
function Shop({ product_type, catagory_id, catagory_name }) {
  const { catagory_id_main, catagory_name_main } = useParams();
  console.log(catagory_id_main, catagory_name_main);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [category, setcategory] = useState([]);
  const [freshproducts, setfreshproducts] = useState([]);
  const [productpath, setproductpath] = useState("");
  const [RupressLeft, setRupressLeft] = useState("");
  const [RupressRight, setRupressRight] = useState(0);
  const [cartdata, setcartdata] = useState([]);
  const [selectedCata, setselectedCata] = useState([]);
  const sortOptions = [
    ["Relevance", "-entry_date"],
    ["Price (Low to High)", "-product_price"],
    ["Price (High to Low)", "product_price"],
    ["Name (A to Z)", "product_name"],
  ];
  useEffect(() => {
    master_data_get("-entry_date");
  }, []);

  const master_data_get = async (order_by_product) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("product_type", product_type);
    fd.append("order_by_product", order_by_product);
    if (catagory_id_main !== undefined) {
      fd.append("catagory_id", catagory_id_main);
    } else {
      fd.append("catagory_id", catagory_id);
    }

    await server_post_data(get_category_product_web_webapp, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfreshproducts(Response.data.message.product_data);
          setcategory(Response.data.message.category);
          if (Response.data.message.selected_category.length > 0) {
            setselectedCata(
              Response.data.message.selected_category[0].category_details
            );
          }

          setproductpath(APL_LINK + Response.data.message.product_image_route);
          setRupressLeft(Response.data.message.rupees_left);
          setRupressRight(Response.data.message.rupess_right);
          setcartdata(Response.data.message.temp_cart_data);
          console.log(Response.data.message.temp_cart_data);
          $("#card_value_add").html(
            Response.data.message.temp_cart_data.length
          );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const formatPrice = (price) => {
    if (price == null || isNaN(price)) {
      return "N/A"; // or any other fallback value you prefer
    }

    return price % 1 === 0 ? `${price}.00` : price.toFixed(2);
  };
  const add_to_cart = async (product_id_dd, product_qty_dd) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("product_id", product_id_dd);
    fd.append("product_qty", product_qty_dd);
    await server_post_data(post_home_temp_cart_webapp, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get("-entry_date");
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div>
      <Header />
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a href="/">
                <strong>
                  <span className="mdi mdi-home"></span> Home
                </strong>
              </a>
              <span className="mdi mdi-chevron-right"></span>
              <a href="#">
                {catagory_name_main !== undefined
                  ? catagory_name_main
                  : catagory_name}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="shop-list section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="shop-filters">
                <div id="accordion">
                  {category &&
                    category.length > 0 &&
                    category.map((category, index) => (
                      <div className="card" key={index}>
                        <div className="card-header" id="headingOne">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {category.category_name}
                              <span className="mdi mdi-chevron-down float-right"></span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordion"
                        >
                          {category.cate_details.length > 0 &&
                            category.cate_details.map((categoryssss, ind2) => (
                              <div className="card-body " key={ind2}>
                                <div
                                  className={`custom-control ${
                                    catagory_id_main == categoryssss.primary_id
                                      ? "color_select_color"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    handleLinkClick(
                                      `/catagory/${categoryssss.category_name}/${categoryssss.primary_id}`
                                    );
                                  }}
                                >
                                  <span>{categoryssss.category_name}</span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <span className="w-100">
                <div className="catagoryTag">
                  <h5>{selectedCata}</h5>
                </div>
              </span>
              <div className="shop-head">
                <a href="/">
                  <span className="mdi mdi-home"></span> Home
                </a>
                <span className="mdi mdi-chevron-right"></span>
                <a href="#">
                  {catagory_name_main !== undefined
                    ? catagory_name_main
                    : catagory_name}
                </a>

                <div className="btn-group float-right mt-2">
                  <button
                    type="button"
                    className="btn btn-dark dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sort by Products &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    {sortOptions.map((sortpption, index) => (
                      <a
                        className="dropdown-item"
                        onClick={() => master_data_get(sortpption[1])}
                        key={index}
                      >
                        {sortpption[0]}
                      </a>
                    ))}
                  </div>
                </div>
                <h5 className="mb-3">
                  {" "}
                  {catagory_name_main !== undefined
                    ? catagory_name_main
                    : catagory_name}
                </h5>
              </div>
              <div className="row no-gutters">
                {freshproducts &&
                  freshproducts.length > 0 &&
                  freshproducts.map((item, index) => {
                    let cartItem = cartdata.find(
                      (data) => item.primary_id === data.product_id
                    );

                    // Initialize count_card to 0
                    let count_card = 0;

                    // If corresponding data is found in cartdata, update count_card
                    if (cartItem) {
                      count_card = cartItem.product_qty;
                    }
                    return (
                      <div className="col-md-4" key={index}>
                        <div className="product">
                          <Link to={`/product_details/${item.primary_id}`}>
                            <div className="product-header">
                              {item.product_disocunt_per > 0 && (
                                <span className="badge badge-success">
                                  {item.product_disocunt_per}% OFF
                                </span>
                              )}
                              <img
                                className="img-fluid"
                                src={`${productpath}${item.product_image1}`}
                                alt={item.product_name}
                              />
                            </div>
                            <div className="product-body">
                              <h5>{item.product_name}</h5>
                              <h6>
                                <strong>
                                  <span className="mdi mdi-approval"></span>{" "}
                                  Available in
                                </strong>{" "}
                                - {item.product_qty} {item.product_qty_unit}
                              </h6>
                            </div>
                          </Link>
                          <div className="product-footer">
                            {item.out_of_stock_status > 0 ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm float-right"
                                >
                                  <i className="mdi mdi-cart-outline" /> Out Of
                                  Stock
                                </button>
                              </>
                            ) : (
                              <>
                                {count_card > 0 ? (
                                  <div className="addQuantity">
                                    <div
                                      onClick={() =>
                                        add_to_cart(item.primary_id, "min")
                                      }
                                    >
                                      <i className="mdi mdi-minus" />
                                    </div>
                                    <div>{count_card}</div>
                                    <div
                                      onClick={() =>
                                        add_to_cart(item.primary_id, "plus")
                                      }
                                    >
                                      <i className="mdi mdi-plus" />
                                    </div>
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-sm float-right"
                                    onClick={() =>
                                      add_to_cart(item.primary_id, "plus")
                                    }
                                  >
                                    <i className="mdi mdi-cart-outline" /> Add
                                    To Cart
                                  </button>
                                )}
                              </>
                            )}

                            <p className="offer-price mb-0">
                              {parseInt(item.product_disocunt_price) > 0 ? (
                                <>
                                  {RupressLeft}
                                  {formatPrice(
                                    item.product_disocunt_price
                                  )}{" "}
                                  {RupressRight}
                                  <i className="mdi mdi-tag-outline" />
                                  <br />
                                  <span className="regular-price">
                                    {RupressLeft}
                                    {formatPrice(item.product_price)}
                                    {RupressRight}
                                  </span>
                                </>
                              ) : (
                                <div className="mt-31">
                                  {RupressLeft}
                                  {formatPrice(item.product_price)}{" "}
                                  {RupressRight}
                                </div>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Shop;
