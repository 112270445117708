import React, { useState, useEffect } from "react";
import apple from "../Assests/images/apple.png";
import google from "../Assests/images/google.png";
import { website_logo, website_name } from "../CommonJquery/WebsiteText";
import {
  server_post_data,
  get_business_data,
  Website_URL,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";
function Foter() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [data_business, setdata_business] = useState([]);
  const [categorys_data, setcategorys_data] = useState([]);
  const [ios_link, setios_link] = useState([]);
  const [android_link, setandroid_link] = useState([]);
  const Yearget = () => {
    const today = new Date();
    const year = today.getFullYear();
    return year;
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_business_data, null)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setdata_business(Response.data.message.data_business[0]);
          setcategorys_data(Response.data.message.categorys_data);
          setandroid_link(Response.data.message.android_link);
          setios_link(Response.data.message.ios_link);
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  return (
    <>
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <section className="section-padding bg-white border-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="feature-box">
                <i className="mdi mdi-truck-fast" />
                <h6>Same Day Delivery</h6>
                <p>
                  Swift delivery ensuring products reach customers on purchase
                  day.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature-box">
                <i className="mdi mdi-basket" />
                <h6>100% Satisfaction Guarantee</h6>
                <p>
                  Complete refund or replacement if unsatisfied, no questions
                  asked.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature-box">
                <i className="mdi mdi-tag-heart" />
                <h6>Great Daily Deals Discount</h6>
                <p>
                  Daily exclusive discounts on varied products, maximizing
                  savings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding footer bg-black border-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <h4 className="mb-5 mt-0">
                <a className="logo" href="/">
                  <img
                    src={website_logo}
                    alt={website_name}
                    className="width50"
                  />
                </a>
              </h4>
              <p className="mb-0">
                <a
                  className="text-dark"
                  href={`https://api.whatsapp.com/send/?phone=${data_business.owner_mobile_no}&text&type=phone_number&app_absent=0`}
                >
                  <i className="mdi mdi-phone" />{" "}
                  {data_business.owner_mobile_no}
                </a>
              </p>
              <p className="mb-0">
                <a
                  className="text-dark"
                  href={`https://api.whatsapp.com/send/?phone=${data_business.owner_other_no}&text&type=phone_number&app_absent=0`}
                >
                  <i className="mdi mdi-cellphone-iphone" />{" "}
                  {data_business.owner_other_no}
                </a>
              </p>
              <p className="mb-0">
                <a
                  className="text-success"
                  href={`mailto:${data_business.owner_email_id}`}
                >
                  <i className="mdi mdi-email" /> {data_business.owner_email_id}
                </a>
              </p>
            </div>

            <div className="col-lg-2 col-md-2">
              <h6 className="mb-4">CATEGORIES</h6>
              <ul>
                {categorys_data &&
                  categorys_data.length > 0 &&
                  categorys_data.map((item, index) => {
                    return (
                      <li key={index}>
                        <a
                          href={`/catagory/${item.category_name}/${item.primary_id}`}
                        >
                          {item.category_name}
                        </a>
                      </li>
                    );
                  })}
                <ul></ul>
              </ul>
            </div>
            <div className="col-lg-2 col-md-2">
              <h6 className="mb-4">ABOUT US</h6>
              <ul>
                {/* <li>
                  <a href="/about">About Us</a>
                </li> */}
                <li>
                  <Link to="/contact_us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>

                <ul></ul>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3">
              <h6 className="mb-4">Download App</h6>
              <div className="app">
                <a href={android_link + data_business.android_apk_link}>
                  <img src={apple} alt="" />
                </a>
                <a href={ios_link + data_business.ios_apk_link}>
                  <img src={google} alt="" />
                </a>
              </div>
              <h6 className="mb-3 mt-4">GET IN TOUCH</h6>
              <div className="footer-social">
                <a
                  className="btn-facebook"
                  href={data_business.facebook_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="mdi mdi-facebook" />
                </a>
                <a
                  className="btn-twitter"
                  href={data_business.twitter_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="mdi mdi-twitter" />
                </a>
                <a
                  className="btn-instagram"
                  href={data_business.instargram_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="mdi mdi-instagram" />
                </a>
                <a
                  className="btn-whatsapp"
                  href={`https://api.whatsapp.com/send/?phone=${data_business.owner_mobile_no}&text&type=phone_number&app_absent=0`}
                >
                  <i className="mdi mdi-whatsapp" />
                </a>

                <a
                  className="btn-google"
                  href={`mailto:${data_business.owner_email_id}`}
                >
                  <i className="mdi mdi-google" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 pb-4 footer-bottom">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-6">
              <p className="mt-1 mb-0">
                © Copyright {Yearget()}{" "}
                <strong className="text-dark">
                  {data_business.business_name}
                </strong>
                . All Rights Reserved
                <br />
                <small className="mt-0 mb-0">
                  Made with <i className="mdi mdi-heart text-danger" /> by{" "}
                  <a
                    href={Website_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary"
                  >
                    Shopup
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Foter;
