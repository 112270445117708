import React, { useState, useEffect } from "react";
import {
  APL_LINK,
  get_customer_temp_data,
  server_post_data,
  post_home_temp_cart_webapp,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";
import $ from "jquery";
import foodblog3 from "../Assests/images/no_card_found.png";
function Sidecart({ isSidebarOpen, toggleSidebar }) {
  const [tempcart, settempcart] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [productpath, setproductpath] = useState("");
  const [ProductTotal, setProductTotal] = useState(0);
  const [TaxTotal, setTaxTotal] = useState(0);
  const [TaxperTotal, setTaxperTotal] = useState(0);
  const [PackingTotal, setPackingTotal] = useState(0);
  const [DeliveryTotal, setDeliveryTotal] = useState(0);
  const [FinalTotal, setFinalTotal] = useState(0);
  const [RupressLeft, setRupressLeft] = useState("");
  const [RupressRight, setRupressRight] = useState(0);
  const [DATANOTFOUND, setDATANOTFOUND] = useState(false);
  console.log(isSidebarOpen);
  useEffect(() => {
    master_data_get(0, 0, 0, 1);
  }, []);

  const master_data_get = async (
    address_id,
    order_type,
    promocode_id,
    first_last
  ) => {
    if (first_last === 0) {
      setshowLoaderAdmin(true);
    } else {
      setshowLoaderAdmin(true);
    }

    const Data = new FormData();

    Data.append("address_id", address_id);
    Data.append("order_type", order_type);
    Data.append("promocode_id", promocode_id);
    await server_post_data(get_customer_temp_data, Data)
      .then((Response) => {
        if (first_last === 0) {
          setshowLoaderAdmin(false);
        } else {
          setshowLoaderAdmin(false);
        }
        if (Response.error) {
          console.log("Error", Response.data.message);
        } else {
          console.log(Response.data.message);
          const cart = Response.data.message.temp_cart_data;
          if (cart.length > 0) {
            setDATANOTFOUND(false);
            settempcart(Response.data.message.temp_cart_data);
            setProductTotal(Response.data.message.product_total);
            setTaxTotal(Response.data.message.tax_charge);
            setTaxperTotal(Response.data.message.tax_per);
            setPackingTotal(Response.data.message.packing_charge);
            setDeliveryTotal(Response.data.message.delivery_charge);
            setFinalTotal(Response.data.message.final_charge);
            setRupressLeft(Response.data.message.rupees_left);
            setRupressRight(Response.data.message.rupess_right);
            setproductpath(
              APL_LINK + Response.data.message.product_image_route
            );
          } else {
            setDATANOTFOUND(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (first_last === 0) {
          setshowLoaderAdmin(false);
        } else {
          setshowLoaderAdmin(false);
        }
        console.log("Error", "Something Went Wrong");
      });
  };

  const add_to_cart = async (product_id_dd, product_qty_dd) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("product_id", product_id_dd);
    fd.append("product_qty", product_qty_dd);
    await server_post_data(post_home_temp_cart_webapp, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get(0, 0, 0, 1);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const formatPrice = (price) => {
    return price % 1 === 0 ? `${price}.00` : price.toFixed(2);
  };
  return (
    <>
      <div className="cart-sidebar">
        <div className={showLoaderAdmin ? "loading" : ""}></div>
        <div className="cart-sidebar-header">
          <h5>
            My Cart <span className="text-success">({tempcart.length})</span>{" "}
            <Link
              data-toggle="offcanvas"
              className="float-right"
              onClick={toggleSidebar}
            >
              <i className="mdi mdi-close" />
            </Link>
          </h5>
        </div>
        {DATANOTFOUND ? (
          <>
            <div className="centerImage">
              <img
                className="card-img-top"
                src={foodblog3}
                alt="Card image cap"
                style={{ width: "250px" }}
              />
            </div>
          </>
        ) : (
          <>
            {tempcart && tempcart.length > 0 && (
              <div className="cart-sidebar-body">
                {tempcart.map((item, index) => {
                  const productInfo =
                    item.productinformation && item.productinformation[0];
                  if (!productInfo) {
                    console.warn(
                      `Product information is missing for item at index ${index}`
                    );
                    return null;
                  }
                  return (
                    <div className="cart-list-product" key={index}>
                      <img
                        className="img-fluid"
                        src={`${productpath}${productInfo["product_image"]}`}
                        alt=""
                      />
                      {productInfo["product_disocunt_per"] > 0 && (
                        <span className="badge badge-success">
                          {productInfo["product_disocunt_per"]}% OFF
                        </span>
                      )}

                      <h5>
                        <a href="#">{productInfo["product_name"]}</a>
                      </h5>
                      <h6>
                        <strong>
                          <span className="mdi mdi-approval" /> Available in
                        </strong>{" "}
                        - {productInfo["product_qty"]}{" "}
                      </h6>
                      <p className="offer-price mb-0">
                        {productInfo["product_disocunt_price"] > 0 ? (
                          <>
                            {RupressLeft}
                            {formatPrice(
                              productInfo["product_disocunt_price"]
                            )}{" "}
                            {RupressRight}
                            <i className="mdi mdi-tag-outline" />
                            <br />
                            <span className="regular-price">
                              {RupressLeft}
                              {formatPrice(productInfo["product_price"])}
                              {RupressRight}
                            </span>
                          </>
                        ) : (
                          <div>
                            {RupressLeft}
                            {productInfo["product_price"]} {RupressRight}
                          </div>
                        )}
                      </p>

                      <div className="addQuantity">
                        <div
                          onClick={() => add_to_cart(item.product_id, "min")}
                        >
                          <i className="mdi mdi-minus" />
                        </div>
                        <div>{item.product_qty}</div>
                        <div
                          onClick={() => add_to_cart(item.product_id, "plus")}
                        >
                          <i className="mdi mdi-plus" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="cart-sidebar-footer">
              <div className="cart-store-details">
                <p>
                  Sub Total{" "}
                  <strong className="float-right">
                    {RupressLeft}
                    {ProductTotal}
                    {RupressRight}
                  </strong>
                </p>
                <p>
                  Tax Charges ({TaxperTotal}%){" "}
                  <strong className="float-right">
                    {RupressLeft}
                    {TaxTotal}
                    {RupressRight}
                  </strong>
                </p>
                <p>
                  Packing Charges{" "}
                  <strong className="float-right text-danger">
                    {RupressLeft}
                    {PackingTotal}
                    {RupressRight}
                  </strong>
                </p>
                <p>
                  Delivery Charges{" "}
                  <strong className="float-right text-danger">
                    {RupressLeft}
                    {DeliveryTotal}
                    {RupressRight}
                  </strong>
                </p>
                <h6>
                  Grand total{" "}
                  <strong className="float-right text-danger">
                    {RupressLeft}
                    {FinalTotal}
                    {RupressRight}
                  </strong>
                </h6>
              </div>
              <a href="/checkout">
                <button
                  className="btn btn-secondary btn-lg btn-block text-left"
                  type="button"
                  data-toggle="offcanvas"
                >
                  <span className="float-left">
                    <i className="mdi mdi-cart-outline" /> Proceed to Checkout{" "}
                  </span>
                  <span className="float-right">
                    <strong>
                      {RupressLeft}
                      {FinalTotal}
                      {RupressRight}
                    </strong>{" "}
                    <span className="mdi mdi-chevron-right" />
                  </span>
                </button>
              </a>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Sidecart;
