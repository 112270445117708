import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import { v4 as uuidv4 } from "uuid";
const appauth_key = "shopup@2029";
let APL_LINK = "http://192.168.1.11:8000/";
APL_LINK = "https://backend.shopupapp.in/";
let Website_URL = "https://www.shopupapp.in/";
let local_server_link_react = APL_LINK + "api/shop_link";

// API functions
//api for backend

const apiKey_Google = "AIzaSyCpeWUQ8s6CkQ_JL5dMO7kXL_yFTM45zbo";
//api for backend

const Login_url = local_server_link_react + "/login_customer_shop_web_app";
const get_home_Banner_category_product_webapp =
  local_server_link_react + "/get_home_Banner_category_product_webapp";
const get_home_category_to_subcategary_webapp =
  local_server_link_react + "/get_home_category_to_subcategary_webapp";
const get_search_product_webapp =
  local_server_link_react + "/get_search_product_webapp";
const get_my_profile_data =
  local_server_link_react + "/get_my_profile_data_app";
const post_home_temp_cart_webapp =
  local_server_link_react + "/post_home_temp_cart_webapp";
const post_favorites_data_webapp =
  local_server_link_react + "/post_favorites_data_webapp";
const get_customer_temp_data =
  local_server_link_react + "/get_customer_temp_data";
const post_customer_order_webapp =
  local_server_link_react + "/post_customer_order_webapp";
const get_home_product_data_webapp =
  local_server_link_react + "/get_home_product_data_webapp";
const update_customer_data_webapp =
  local_server_link_react + "/update_customer_data_webapp";
const get_customer_order_data_webapp =
  local_server_link_react + "/get_customer_order_data_webapp";
const get_my_address_data_app =
  local_server_link_react + "/get_my_address_data_app";
const post_customer_address_data_webapp =
  local_server_link_react + "/post_customer_address_data_webapp";
const get_terms_policy_data =
  local_server_link_react + "/get_terms_policy_data";
const post_home_customer_help_webapp =
  local_server_link_react + "/post_home_customer_help_webapp";
const get_business_data = local_server_link_react + "/get_business_data";
const get_category_product_web_webapp =
  local_server_link_react + "/get_category_product_web_webapp";
const delete_address_data_app =
  local_server_link_react + "/delete_address_data_app";
/**website links */
// Retrieving data

const retrievedAdminId = retrieveData("customer_id");
const server_post_data = async (url_for, form_data) => {
  if (form_data === null) {
    form_data = new FormData();
  }
  const sessionKey = "sessionId";
  let sessionId = localStorage.getItem(sessionKey);
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem(sessionKey, sessionId);
  }

  form_data.append("firebase_token", sessionId);
  form_data.append("customer_id", retrievedAdminId);
  form_data.append("carting_from", "web");
  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,
  Login_url,
  apiKey_Google,
  get_home_Banner_category_product_webapp,
  get_home_category_to_subcategary_webapp,
  post_home_temp_cart_webapp,
  post_favorites_data_webapp,
  get_search_product_webapp,
  get_customer_temp_data,
  get_my_profile_data,
  post_customer_order_webapp,
  get_home_product_data_webapp,
  update_customer_data_webapp,
  get_customer_order_data_webapp,
  get_my_address_data_app,
  post_customer_address_data_webapp,
  get_terms_policy_data,
  post_home_customer_help_webapp,
  get_business_data,
  get_category_product_web_webapp,
  delete_address_data_app,
  //website Links
};
