import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "./Assests/css2/osahan.css";
import "./icons/css/materialdesignicons.min.css";
import "./CommonJquery/custom.js";
import "select2/dist/js/select2.full";
import "select2/dist/css/select2.css";
import Home from "./Components/Home";
import ProductSingle from "./Components/ProductSingle.js";
import Shop from "./Components/Shop.js";
import CheckOut from "./Components/CheckOut.js";
import Contact from "./Components/Contact.js";
import MyAccount from "./Components/MyAccount.js";
import Websitecontent from "./Components/Websitecontent.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoFoundError from "./Components/NoFoundError.js";
import About from "./Components/About.js";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/privacy-policy"
            element={<Websitecontent flag="2" page_name="Privacy Policy" />}
          />
          <Route
            path="/terms-and-conditions"
            element={<Websitecontent flag="1" page_name="Terms & Conditions" />}
          />
          <Route
            path="/product_details/:productId"
            element={<ProductSingle />}
          />

          <Route
            path="catagory/new-trending"
            element={
              <Shop
                product_type="3"
                catagory_id="0"
                catagory_name="New Trending"
              />
            }
          />
          <Route
            path="catagory/new-arrive"
            element={
              <Shop
                product_type="1"
                catagory_id="0"
                catagory_name="New Arrive"
              />
            }
          />
          <Route
            path="catagory/new-fresh"
            element={
              <Shop
                product_type="2"
                catagory_id="0"
                catagory_name="New Fresh"
              />
            }
          />
          <Route
            path="catagory/:catagory_name_main/:catagory_id_main"
            element={
              <Shop
                product_type="category"
                catagory_id="0"
                catagory_name="0000"
              />
            }
          />

          <Route path="/contact_us" element={<Contact />} />
          <Route path="/checkout" element={<CheckOut />} />

          <Route path="/my_profile" element={<MyAccount />} />
          <Route path="/*" element={<NoFoundError />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </div>
  );
}

export default App;
