import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Foter";
import {
  get_business_data,
  server_post_data,
  post_home_customer_help_webapp,
} from "../ServiceConnection/serviceconnection";
import {
  handleAphabetsChange,
  handleError,
  check_vaild_save,
  combiled_form_data,
  handleSuccess,
  handleEmailChange,
  handleIaphabetnumberChange,
  empty_form,
} from "../CommonJquery/CommonJquery.js";
function Contact() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [data_business, setdata_business] = useState(false);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(get_business_data, fd)
      .then((Response) => {
        console.log(Response.data.message.data_business);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setdata_business(Response.data.message.data_business[0]);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccess(Response.data.message);
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };
  return (
    <div>
      <Header />
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <h3 className="mt-1 mb-5">Get In Touch</h3>
              <h6 className="text-dark">
                <i className="mdi mdi-home-map-marker"></i> Address :
              </h6>
              <p>{data_business.business_address}</p>
              <h6 className="text-dark">
                <i className="mdi mdi-deskphone"></i> Mobile :
              </h6>
              <p>
                {data_business.owner_mobile_no},{data_business.owner_other_no}
              </p>
              <h6 className="text-dark">
                <i className="mdi mdi-email"></i> Email :
              </h6>
              <p>{data_business.owner_email_id}</p>

              <div className="footer-social footer-social2 ">
                <span>Follow : </span>
                <a href={data_business.facebook_link}>
                  <i className="mdi mdi-facebook"></i>
                </a>
                <a href={data_business.twitter_link}>
                  <i className="mdi mdi-twitter"></i>
                </a>
                <a href={data_business.instargram_link}>
                  <i className="mdi mdi-instagram"></i>
                </a>
                <a href={`mailto:${data_business.owner_email_id}`}>
                  <i className="mdi mdi-google"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="card">
                <div className="card-body">
                  <form id="addNewStaff">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="control-label">
                            Write issue here <span className="required">*</span>
                          </label>
                          <input
                            className="form-control border-form-control trio_mandatory"
                            name="title"
                            id="title"
                            placeholder="Write issue here*"
                            maxLength={100}
                            onInput={handleIaphabetnumberChange}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="control-label">
                            Add comments <span className="required">*</span>
                          </label>
                          <textarea
                            style={{ height: "100px" }}
                            className="form-control border-form-control trio_mandatory"
                            name="description"
                            id="description"
                            onInput={handleIaphabetnumberChange}
                            placeholder="Enter  comments"
                            maxLength={300}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          type="button"
                          className="btn btn-success btn-lg"
                          onClick={() =>
                            handleSaveChangesdynamic(
                              "addNewStaff",
                              post_home_customer_help_webapp
                            )
                          }
                        >
                          {" "}
                          Save Changes{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
