import React, { useEffect, useState } from "react";
import Header from "./Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import {
  server_post_data,
  get_home_product_data_webapp,
  APL_LINK,
  post_home_temp_cart_webapp,
  post_favorites_data_webapp,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import Footer from "./Foter";
import { website_name } from "../CommonJquery/WebsiteText.js";
import heart from "../Assests/images/heart.png";
import heartRed from "../Assests/images/heartRed.png";

function ProductSingle() {
  const { productId } = useParams();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [freshproducts, setfreshproducts] = useState([]);
  const [singleProductDetails, setSingleProductDetails] = useState([]);
  const [singleProductimg, setSingleProductimg] = useState("");
  const [productpath, setproductpath] = useState("");
  const [CountCardFinal, setCountCardFinal] = useState(0);
  const [RupressLeft, setRupressLeft] = useState("");
  const [RupressRight, setRupressRight] = useState(0);
  const [FavouriteFinal, setFavouriteFinal] = useState(0);
  const [cartdata, setcartdata] = useState([]);
  const [carouselKey, setCarouselKey] = useState(0);
  useEffect(() => {
    master_data_get_single();
  }, []);
  const options3 = {
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 5,
      },
      1200: {
        items: 5,
      },
    },
    lazyLoad: true,
    pagination: "false",
    loop: true,
    dots: false,
    autoplay: false,
    nav: true,
    navText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    rtl: "true",
    autoplayHoverPause: true,
  };
  const master_data_get_single = async () => {
    var form_data = new FormData();
    form_data.append("call_id", productId);
    await server_post_data(get_home_product_data_webapp, form_data)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSingleProductDetails(Response.data.message.product_data[0]);
          setSingleProductimg(Response.data.message.product_image);
          setRupressLeft(Response.data.message.rupees_left);
          setRupressRight(Response.data.message.rupess_right);
          setcartdata(Response.data.message.temp_cart_data2);
          if (Response.data.message.fav_data.length > 0) {
            setFavouriteFinal(1);
          }
          if (Response.data.message.temp_cart_data.length > 0) {
            setCountCardFinal(
              Response.data.message.temp_cart_data[0].product_qty
            );
          }
          setfreshproducts(Response.data.message.category_related_product);
          setproductpath(APL_LINK + Response.data.message.product_image_route);
          $("#card_value_add").html(
            Response.data.message.temp_cart_data2.length
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setCarouselKey((prevKey) => prevKey + 1);
  }, [freshproducts]);
  const formatPrice = (price) => {
    if (price == null || isNaN(price)) {
      return "N/A";
    }

    return price % 1 === 0 ? `${price}.00` : price.toFixed(2);
  };

  const add_to_cart = async (product_id_dd, product_qty_dd) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("product_id", product_id_dd);
    fd.append("product_qty", product_qty_dd);
    await server_post_data(post_home_temp_cart_webapp, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get_single();
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const add_to_favourite = async (product_id_dd) => {
    setshowLoaderAdmin(true);
    let Data = new FormData();
    Data.append("product_id", product_id_dd);
    await server_post_data(post_favorites_data_webapp, Data)
      .then(async (Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          console.log("Error", Response.data.message);
        } else {
          setFavouriteFinal(parseInt(Response.data.message));
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        console.log("Error", "Something Went Wrong");
      });
  };
  console.log(FavouriteFinal);

  const sync1Config = {
    singleItem: true,
    items: 1,
    rtl: true,
    slideSpeed: 1000,
    pagination: false,
    navigation: true,
    autoPlay: 2500,
    dots: true,
    nav: true,
    navigationText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    responsiveRefreshRate: 200,
  };

  const sync2Config = {
    items: 5,
    rtl: true,
    navigation: true,
    dots: true,
    pagination: false,
    nav: true,
    navigationText: [
      "<i class='mdi mdi-chevron-left'></i>",
      "<i class='mdi mdi-chevron-right'></i>",
    ],
    responsiveRefreshRate: 100,
    afterInit: function (el) {
      el.find(".owl-item").eq(0).addClass("synced");
    },
  };

  console.log(singleProductDetails);

  return (
    <div>
      <Header />
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      {/* Shop Single */}
      <section className="shop-single section-padding pt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="shop-detail-left">
                <div className="shop-detail-slider">
                  {singleProductDetails.product_disocunt_price > 0 && (
                    <div className="favourite-icon">
                      <a
                        className="fav-btn"
                        title=""
                        data-placement="bottom"
                        data-toggle="tooltip"
                        href="#"
                        data-original-title="59% OFF"
                      >
                        <i className="mdi mdi-tag-outline"></i>
                      </a>
                    </div>
                  )}
                  {singleProductimg && singleProductimg.length > 0 && (
                    <OwlCarousel
                      id="sync1"
                      {...sync1Config}
                      className="owl-carousel owl-theme  owl-carousel-featured"
                    >
                      {singleProductimg.map((item, index) => (
                        <div
                          className="item"
                          key={index}
                          style={{ maxHeight: "500px" }}
                        >
                          <img
                            alt=""
                            src={`${productpath}${item.product_image}`}
                            className="img-fluid img-center"
                          />
                        </div>
                      ))}
                    </OwlCarousel>
                  )}
                  {singleProductimg && singleProductimg.length > 0 && (
                    <OwlCarousel
                      id="sync2"
                      {...sync2Config}
                      className="owl-carousel owl-theme"
                    >
                      {singleProductimg.map((item, index) => (
                        <div className="item" key={index}>
                          <img
                            alt={singleProductDetails.product_name}
                            src={`${productpath}${item.product_image1}`}
                            className="img-fluid img-center"
                          />
                        </div>
                      ))}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="shop-detail-right">
                <div
                  className={`${
                    singleProductDetails.product_disocunt_per > 0
                      ? "shop-detail-right_head"
                      : "shop-detail-right_head1"
                  }`}
                >
                  {singleProductDetails.product_disocunt_per > 0 && (
                    <span className="badge badge-success">
                      {singleProductDetails.product_disocunt_per}% OFF
                    </span>
                  )}
                  {FavouriteFinal > 0 ? (
                    <button
                      className="wishlistBtn"
                      type="button"
                      onClick={() =>
                        add_to_favourite(singleProductDetails.primary_id)
                      }
                    >
                      <img src={heartRed} alt="Wishlist Icon" />
                    </button>
                  ) : (
                    <button
                      className="wishlistBtn"
                      type="button"
                      onClick={() =>
                        add_to_favourite(singleProductDetails.primary_id)
                      }
                    >
                      <img
                        className="imageBlack"
                        src={heart}
                        alt="Wishlist Icon"
                      />
                      <img
                        className="imageRed"
                        src={heartRed}
                        alt="Wishlist Icon"
                      />
                    </button>
                  )}
                </div>
                <h2>{singleProductDetails.product_name}</h2>
                <h6>
                  <strong>
                    <span className="mdi mdi-approval"></span> Available in
                  </strong>{" "}
                  -
                  <span className="available">
                    {singleProductDetails.product_qty}{" "}
                    {singleProductDetails.product_qty_unit}
                  </span>
                </h6>
                {singleProductDetails.product_disocunt_price > 0 ? (
                  <>
                    <p className="regular-price">
                      <i className="mdi mdi-tag-outline"></i> MRP :{" "}
                      {RupressLeft}
                      {formatPrice(singleProductDetails.product_price)}{" "}
                      {RupressRight}
                    </p>
                    <p className="offer-price mb-0">
                      Discounted price :{" "}
                      <span className="text-success">
                        {RupressLeft}
                        {formatPrice(
                          singleProductDetails.product_disocunt_price
                        )}{" "}
                        {RupressRight}
                      </span>
                    </p>
                  </>
                ) : (
                  <p className="offer-price mb-0">
                    MRP price :{" "}
                    <span className="text-success">
                      {RupressLeft}
                      {formatPrice(singleProductDetails.product_price)}{" "}
                      {RupressRight}
                    </span>
                  </p>
                )}

                {singleProductDetails.out_of_stock_status > 0 ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm float-right"
                    >
                      <i className="mdi mdi-cart-outline" /> Out Of Stock
                    </button>
                  </>
                ) : (
                  <>
                    {CountCardFinal > 0 ? (
                      <div className="addQuantity_single singlePRo btn btn-secondary btn-lg ">
                        <div
                          onClick={() =>
                            add_to_cart(singleProductDetails.primary_id, "min")
                          }
                        >
                          <i className="mdi mdi-minus" />
                        </div>
                        <div>{CountCardFinal}</div>
                        <div
                          onClick={() =>
                            add_to_cart(singleProductDetails.primary_id, "plus")
                          }
                        >
                          <i className="mdi mdi-plus" />
                        </div>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-secondary btn-lg"
                        onClick={() =>
                          add_to_cart(singleProductDetails.primary_id, "plus")
                        }
                      >
                        <i className="mdi mdi-cart-outline" /> Add To Cart
                      </button>
                    )}
                  </>
                )}
                <div className="short-description">
                  <h5>Product Overview</h5>
                  <p>{singleProductDetails.product_detail}</p>
                  <p className="mb-0">
                    {singleProductDetails.product_highlight}
                  </p>
                </div>
                <h6 className="mb-3 mt-4">Why shop from {website_name}?</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="feature-box">
                      <i className="mdi mdi-truck-fast"></i>
                      <h6 className="text-info">Free Delivery</h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="feature-box">
                      <i className="mdi mdi-basket"></i>
                      <h6 className="text-info">100% Guarantee</h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="feature-box">
                      <i className="mdi mdi-tag-heart"></i>
                      <h6 className="text-info">Huge Savings</h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="feature-box">
                      <i className="mdi mdi-refresh"></i>
                      <h6 className="text-info">Easy Returns</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Related Products */}
      {freshproducts && freshproducts.length > 0 && (
        <section className="product-items-slider section-padding">
          <div className="container">
            <div className="section-header">
              <h5 className="heading-design-h5">Related Products</h5>
            </div>

           <div className="row">
           {freshproducts.map((item, index) => {
                let cartItem = cartdata.find(
                  (data) => item.primary_id === data.product_id
                );

                // Initialize count_card to 0
                let count_card = 0;

                // If corresponding data is found in cartdata, update count_card
                if (cartItem) {
                  count_card = cartItem.product_qty;
                }
                return (
                  <div className="item col-sm-3" key={index}>
                    <div className="product">
                      <Link to={`/product_details/${item.primary_id}`}>
                        <div className="product-header">
                          {item.product_disocunt_per > 0 && (
                            <span className="badge badge-success">
                              {item.product_disocunt_per}% OFF
                            </span>
                          )}

                          <img
                            className="img-fluid"
                            src={`${productpath}${item.product_image1}`}
                            alt={item.product_name}
                          />
                        </div>
                        <div className="product-body">
                          <h5 className="ellipsis-2-lines">
                            {item.product_name}
                          </h5>
                          <h6>
                            <strong>
                              <span className="mdi mdi-approval" /> Available in
                            </strong>{" "}
                            - {item.product_qty} {item.product_qty_unit}
                          </h6>
                        </div>
                      </Link>
                      <div className="product-footer">
                        {item.out_of_stock_status > 0 ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm float-right"
                            >
                              <i className="mdi mdi-cart-outline" /> Out Of
                              Stock
                            </button>
                          </>
                        ) : (
                          <>
                            {count_card > 0 ? (
                              <div className="addQuantity">
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "min")
                                  }
                                >
                                  <i className="mdi mdi-minus" />
                                </div>
                                <div>{count_card}</div>
                                <div
                                  onClick={() =>
                                    add_to_cart(item.primary_id, "plus")
                                  }
                                >
                                  <i className="mdi mdi-plus" />
                                </div>
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm float-right"
                                onClick={() =>
                                  add_to_cart(item.primary_id, "plus")
                                }
                              >
                                <i className="mdi mdi-cart-outline" /> Add To
                                Cart
                              </button>
                            )}
                          </>
                        )}

                        <p className="offer-price mb-0">
                          {parseInt(item.product_disocunt_price) > 0 ? (
                            <>
                              {RupressLeft}
                              {formatPrice(item.product_disocunt_price)}{" "}
                              {RupressRight}
                              <i className="mdi mdi-tag-outline" />
                              <br />
                              <span className="regular-price">
                                {RupressLeft}
                                {formatPrice(item.product_price)}
                                {RupressRight}
                              </span>
                            </>
                          ) : (
                            <div className="mt-31">
                              {RupressLeft}
                              {formatPrice(item.product_price)} {RupressRight}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
           </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}

export default ProductSingle;
