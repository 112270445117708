import React, { useState, useEffect } from "react";

import Footer from "./Foter";
import Header from "./Header";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  server_post_data,
  get_terms_policy_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
function Websitecontent({ flag_click, page_name }) {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag_click", flag_click);
    await server_post_data(get_terms_policy_data, null)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          sethtmlContent(Response.data.message.text_show_html);
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div>
      <Header />
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <section className="section-padding bg-dark inner-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="mt-0 mb-3 text-white">{page_name}</h1>
              <div className="breadcrumbs">
                <p className="mb-0 text-white">
                  <Link className="text-white" to="/">
                    Home
                  </Link>{" "}
                  / <span className="text-success">{page_name}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding bg-white">
        <div className="container">
          <div className="row">
            <h3>{page_name}</h3>
            <div className="col-lg-8 col-md-8 pl-5 pr-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(htmlContent),
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Websitecontent;
