import React, { useEffect, useState } from "react";
import {
  server_post_data,
  get_search_product_webapp,
  post_home_temp_cart_webapp,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { handleError, formatPrice } from "../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";
import nodatafound from "../Assests/images/no_favorite_found.png";
function WishList() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [DATANOTFOUND, setDATANOTFOUND] = useState(false);
  const [newproducts, setnewproducts] = useState([]);
  const [productpath, setproductpath] = useState("");
  const [cartdata, setcartdata] = useState([]);
  const [RupressLeft, setRupressLeft] = useState("");
  const [RupressRight, setRupressRight] = useState(0);
  useEffect(() => {
    get_all_front();
  }, []);

  const get_all_front = async () => {
    setshowLoaderAdmin(true);
    let formdata = new FormData();
    formdata.append("normal_fave", "1");
    await server_post_data(get_search_product_webapp, formdata)
      .then(async (Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.product_data.length > 0) {
            setnewproducts(Response.data.message.product_data);
            setDATANOTFOUND(false);
          } else {
            setDATANOTFOUND(true);
          }
          setproductpath(APL_LINK + Response.data.message.product_image_route);
          setRupressLeft(Response.data.message.rupees_left);
          setRupressRight(Response.data.message.rupess_right);
          setcartdata(Response.data.message.temp_cart_data);
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        setDATANOTFOUND(true);
        handleError("Something Went Wrong");
      });
  };
  const add_to_cart = async (product_id_dd, product_qty_dd) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("product_id", product_id_dd);
    fd.append("product_qty", product_qty_dd);
    await server_post_data(post_home_temp_cart_webapp, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          get_all_front();
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  return (
    <div className="card card-body account-right">
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <div className="widget">
        <div className="section-header">
          <h5 className="heading-design-h5">Wishlist</h5>
        </div>
        {newproducts && newproducts.length > 0 && (
          <div className="row no-gutters">
            {newproducts.map((item, index) => {
              let cartItem = cartdata.find(
                (data) => item.primary_id === data.product_id
              );

              // Initialize count_card to 0
              let count_card = 0;

              // If corresponding data is found in cartdata, update count_card
              if (cartItem) {
                count_card = cartItem.product_qty;
              }
              return (
                <div className="col-md-6" key={index}>
                  <div className="product">
                    <Link to={`/product_details/${item.primary_id}`}>
                      <div className="product-header">
                        {item.product_disocunt_per > 0 && (
                          <span className="badge badge-success">
                            {item.product_disocunt_per}% OFF
                          </span>
                        )}
                        <img
                          className="img-fluid"
                          src={`${productpath}${item.product_image1}`}
                          alt={item.product_name}
                        />
                      </div>
                    </Link>
                    <div className="product-body">
                      <h5>{item.product_name}</h5>
                      <h6>
                        <strong>
                          <span className="mdi mdi-approval" /> Available in
                        </strong>{" "}
                        - {item.product_qty} {item.product_qty_unit}
                      </h6>
                    </div>
                    <div className="product-footer">
                      {item.out_of_stock_status > 0 ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm float-right"
                          >
                            <i className="mdi mdi-cart-outline" /> Out Of Stock
                          </button>
                        </>
                      ) : (
                        <>
                          {count_card > 0 ? (
                            <div className="addQuantity">
                              <div
                                onClick={() =>
                                  add_to_cart(item.primary_id, "min")
                                }
                              >
                                <i className="mdi mdi-minus" />
                              </div>
                              <div>{count_card}</div>
                              <div
                                onClick={() =>
                                  add_to_cart(item.primary_id, "plus")
                                }
                              >
                                <i className="mdi mdi-plus" />
                              </div>
                            </div>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm float-right"
                              onClick={() =>
                                add_to_cart(item.primary_id, "plus")
                              }
                            >
                              <i className="mdi mdi-cart-outline" /> Add To Cart
                            </button>
                          )}
                        </>
                      )}

                      <p className="offer-price mb-0">
                        {parseInt(item.product_disocunt_price) > 0 ? (
                          <>
                            {RupressLeft}
                            {formatPrice(item.product_disocunt_price)}{" "}
                            {RupressRight}
                            <i className="mdi mdi-tag-outline" />
                            <br />
                            <span className="regular-price">
                              {RupressLeft}
                              {formatPrice(item.product_price)}
                              {RupressRight}
                            </span>
                          </>
                        ) : (
                          <div className="mt-31">
                            {RupressLeft}
                            {formatPrice(item.product_price)} {RupressRight}
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {DATANOTFOUND && (
          <div className="text-center">
            <img src={nodatafound} alt="Card image cap" />
          </div>
        )}
      </div>
    </div>
  );
}

export default WishList;
