import React, { useState, useEffect } from "react";
import {
  APL_LINK,
  get_customer_temp_data,
  server_post_data,
  post_home_temp_cart_webapp,
  post_customer_order_webapp,
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery.js";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Foter";
import Close from "../Assests/images/close.webp"
import { Button, Modal } from "react-bootstrap";
import $ from "jquery";
let customer_city = "";
let customer_address = "";
let customer_id = "";
let customer_address_id = "0";
function CheckOut() {
  const [currentStep, setCurrentStep] = useState(1);
  const [tempcart, settempcart] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [promocodelist, setpromocodelist] = useState([]);
  const [productpath, setproductpath] = useState("");
  const [ProductTotal, setProductTotal] = useState(0);
  const [TaxTotal, setTaxTotal] = useState(0);
  const [TaxperTotal, setTaxperTotal] = useState(0);
  const [PackingTotal, setPackingTotal] = useState(0);
  const [DeliveryTotal, setDeliveryTotal] = useState(0);
  const [PromocodeTotal, setPromocodeTotal] = useState(0);
  const [WalletTotal, setWalletTotal] = useState(0);
  const [addresslist, setaddresslist] = useState([]);
  const [FinalTotal, setFinalTotal] = useState(0);
  const [RupressLeft, setRupressLeft] = useState("");
  const [RupressRight, setRupressRight] = useState(0);
  const [OrderType, setOrderType] = useState(0);
  const [PromocodeID, setPromocodeID] = useState(0);
  const [DATANOTFOUND, setDATANOTFOUND] = useState(false);
  const [SelectedAddress, setSelectedAddress] = useState(0);
  const [COUNTERID, setCOUNTERID] = useState(0);
  useEffect(() => {
    customer_city = retrieveData("customer_city");
    customer_id = retrieveData("customer_id");
    customer_address = retrieveData("customer_address");
    if (customer_city !== 0 || customer_city !== null) {
      customer_city = "Address";
      customer_address = "Please Select Address";
    }
    customer_address_id = retrieveData("customer_address_id");

    master_data_get(customer_address_id, OrderType, PromocodeID, 0);
  }, []);

  const handleOptionChange = (event) => {
    setOrderType(event.target.value);
    master_data_get(customer_address_id, event.target.value, PromocodeID, 1);
  };

  const handleAddressChange = (address_id) => {
    setSelectedAddress(address_id);
    final_checkout(address_id);
  };
  const handleNextStep = () => {
    if (parseInt(OrderType) === 1) {
      setCurrentStep(2);
    } else {
      final_checkout(0);
    }
  };

  const final_checkout = async (address_id = 0) => {
    let Data = new FormData();
    if (
      (SelectedAddress != null && SelectedAddress !== 0) ||
      address_id != 0 ||
      OrderType === 0
    ) {
      setshowLoaderAdmin(true);
      if (address_id === 0) {
        Data.append("address_id", SelectedAddress);
      } else {
        Data.append("address_id", address_id);
      }

      Data.append("order_type", OrderType);
      Data.append("promocode_id", PromocodeID);
      Data.append("payment_type", "COD");
      Data.append("special_instruction", "");
      await server_post_data(post_customer_order_webapp, Data)
        .then(async (Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            setCurrentStep(3);
            setCOUNTERID(Response.data.message);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
          handleError("Something Went Wrong");
        });
    } else {
      handleError("please Select Address");
    }
  };

  const master_data_get = async (
    address_id,
    order_type,
    promocode_id,
    first_last
  ) => {
    if (first_last === 0) {
      setshowLoaderAdmin(true);
    }

    const Data = new FormData();

    Data.append("address_id", address_id);
    Data.append("order_type", order_type);
    Data.append("promocode_id", promocode_id);
    await server_post_data(get_customer_temp_data, Data)
      .then((Response) => {
        if (first_last === 0) {
          setshowLoaderAdmin(false);
        }
        if (Response.error) {
          handleError(Response.data.message);
        } else {
          const cart = Response.data.message.temp_cart_data;
          if (cart.length > 0) {
            setDATANOTFOUND(false);
            settempcart(cart);
            setpromocodelist(Response.data.message.promocode_list);
            setaddresslist(Response.data.message.data_address);
            setProductTotal(Response.data.message.product_total);
            setTaxTotal(Response.data.message.tax_charge);
            setTaxperTotal(Response.data.message.tax_per);
            setPackingTotal(Response.data.message.packing_charge);
            setDeliveryTotal(Response.data.message.delivery_charge);
            setPromocodeTotal(Response.data.message.promocode_amount);
            setWalletTotal(Response.data.message.wallet_charge);
            setFinalTotal(Response.data.message.final_charge);
            setRupressLeft(Response.data.message.rupees_left);
            setRupressRight(Response.data.message.rupess_right);
            setproductpath(
              APL_LINK + Response.data.message.product_image_route
            );
            $("#card_value_add").html(
              Response.data.message.temp_cart_data.length
            );
            if (Number(Response.data.message.promocode_amount) > 0) {
              setPromocodeID(promocode_id);
            } else {
              setPromocodeID(0);
            }
          } else {
            setDATANOTFOUND(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (first_last === 0) {
          setshowLoaderAdmin(false);
        }
        console.log("Error", "Something Went Wrong");
      });
  };

  const handlepromocode_press = (itemId) => {
    master_data_get(customer_address_id, OrderType, itemId, 0);
    handleClose();
  };

  const add_to_cart = async (product_id_dd, product_qty_dd) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("product_id", product_id_dd);
    fd.append("product_qty", product_qty_dd);
    await server_post_data(post_home_temp_cart_webapp, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get(customer_address_id, OrderType, PromocodeID, 1);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const formatPrice = (price) => {
    return price % 1 === 0 ? `${price}.00` : price.toFixed(2);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Header />
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a href="/">
                <strong>
                  <span className="mdi mdi-home"></span> Home
                </strong>
              </a>
              <span className="mdi mdi-chevron-right"></span>{" "}
              <a href="#">Checkout</a>
            </div>
          </div>
        </div>
      </section>

      <section className="checkout-page section-padding">
        <div className="container">
          {!DATANOTFOUND && (
            <div className="row">
              <div className="col-md-8">
                <div className="checkout-step">
                  {currentStep === 1 && (
                    <div>
                      <div className="cart-sidebar-header cart-sidebar-header2">
                        <h5>
                          My Cart{" "}
                          <span className="text-success">
                            ({tempcart.length})
                          </span>{" "}
                        </h5>
                      </div>
                      {tempcart && tempcart.length > 0 && (
                        <div className="cart-sidebar-body">
                          {tempcart.map((item, index) => {
                            const productInfo =
                              item.productinformation &&
                              item.productinformation[0];
                            if (!productInfo) {
                              return null;
                            }
                            return (
                              <div className="cart-list-product" key={index}>
                                <img
                                  className="img-fluid"
                                  src={`${productpath}${productInfo["product_image1"]}`}
                                  alt=""
                                />
                                <span className="badge badge-success">
                                  {productInfo["product_disocunt_per"]}% OFF
                                </span>
                                <h5>
                                  <a href="#">{productInfo["product_name"]}</a>
                                </h5>
                                <h6>
                                  <strong>
                                    <span className="mdi mdi-approval" />{" "}
                                    Available in
                                  </strong>{" "}
                                  - {productInfo["product_qty"]}{" "}
                                </h6>
                                <p className="offer-price mb-0">
                                  {productInfo["product_disocunt_price"] > 0 ? (
                                    <>
                                      {RupressLeft}
                                      {formatPrice(
                                        productInfo["product_disocunt_price"]
                                      )}{" "}
                                      {RupressRight}
                                      <i className="mdi mdi-tag-outline" />
                                      <br />
                                      <span className="regular-price">
                                        {RupressLeft}
                                        {formatPrice(
                                          productInfo["product_price"]
                                        )}
                                        {RupressRight}
                                      </span>
                                    </>
                                  ) : (
                                    <div>
                                      {RupressLeft}
                                      {productInfo["product_price"]}{" "}
                                      {RupressRight}
                                    </div>
                                  )}
                                </p>
                                <div className="addQuantity">
                                  <div
                                    onClick={() =>
                                      add_to_cart(item.product_id, "min")
                                    }
                                    className="minplusBtn"
                                  >
                                    <i className="mdi mdi-minus" />
                                  </div>
                                  <div>{item.product_qty}</div>
                                  <div
                                    onClick={() =>
                                      add_to_cart(item.product_id, "plus")
                                    }
                                    className="minplusBtn"
                                  >
                                    <i className="mdi mdi-plus" />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="accordion" id="accordionExample">
                    <div className="card checkout-step-five">
                      <div
                        id="collapseFive"
                        className={`collapse ${
                          currentStep === 3 ? "show" : ""
                        }`}
                        aria-labelledby="headingFive"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="text-center">
                            <div className="col-lg-10 col-md-10 mx-auto order-done">
                              <i className="mdi mdi-check-circle-outline text-secondary"></i>
                              <h4 className="text-success">
                                Congrats! Your Order #{COUNTERID} has been
                                Placed..
                              </h4>
                            </div>
                            <div className="text-center">
                              <Link onClick={() => handleLinkClick("/")}>
                                <button
                                  className="btn btn-secondary btn-lg"
                                  type="button"
                                >
                                  Return to Home
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="card checkout-step-five">
                      <div
                        id="collapseFive"
                        className={`collapse ${
                          currentStep === 2 ? "show" : ""
                        }`}
                        aria-labelledby="headingFive"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="text-center">
                            <ul>
                              {addresslist &&
                                addresslist.length > 0 &&
                                addresslist.map((item, index) => (
                                  <li key={index}>
                                    <div className="promoItem">
                                      <h5 className="text-left">
                                        {item.type_address}
                                      </h5>
                                      <p className="promoDateail text-left">
                                        {item.building},{item.user_city},
                                        {item.user_state},{item.user_country}
                                      </p>
                                      <div className="applyPromo justify-content-end w-100">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleAddressChange(item.primary_id)
                                          }
                                        >
                                          Order Place
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                            <div className="text-center">
                              <a
                                href="#"
                                data-target="#bd-example-modal2"
                                data-toggle="modal"
                                className="btn btn-link"
                              >
                                <i className="mdi mdi-account-circle" /> Add New
                                Address
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card sidebar-card">
                  <div className="card-body">
                    <div className="cart-summary">
                      <div className="cart-summary-header">
                        <h5 className="mb-0">Price Details</h5>
                      </div>
                      <div className="cart-summary-body">
                        <div className="cart-summary-item">
                          <span>Sub Total</span>
                          <span className="float-right">
                            {RupressLeft}
                            {ProductTotal}
                            {RupressRight}
                          </span>
                        </div>
                        <div className="cart-summary-item">
                          <span>Tax Charges({TaxperTotal}%)</span>
                          <span className="float-right">
                            {RupressLeft}
                            {TaxTotal}
                            {RupressRight}
                          </span>
                        </div>
                        <div className="cart-summary-item">
                          <span>Packing Charges</span>
                          <span className="float-right">
                            {RupressLeft}
                            {PackingTotal}
                            {RupressRight}
                          </span>
                        </div>

                        {PromocodeTotal > 0 && (
                          <div className="cart-summary-item">
                            <span>
                              Coupon Discount{" "}
                              <span
                                onClick={() => handlepromocode_press(0)}
                                className="removePromo"
                              >
                                &nbsp;&nbsp;Remove
                              </span>
                            </span>
                            <span className="float-right text-success">
                              - {RupressLeft}
                              {PromocodeTotal}
                              {RupressRight}
                            </span>
                          </div>
                        )}
                        <div className="cart-summary-item">
                          <span>Delivery Charges</span>
                          <span className="float-right text-danger">
                            {RupressLeft}
                            {DeliveryTotal}
                            {RupressRight}
                          </span>
                        </div>
                        <hr />
                        <div className="cart-summary-item orderTotal">
                          <span>Order Total</span>
                          <span className="float-right">
                            {RupressLeft}
                            {FinalTotal}
                            {RupressRight}
                          </span>
                        </div>
                      </div>
                      {currentStep === 1 && (
                        <>
                          <div>
                            <h3>Order Type</h3>
                            <div>
                              <input
                                type="radio"
                                id="option1"
                                name="sortOption"
                                value="0"
                                defaultChecked={OrderType === 0}
                                onChange={handleOptionChange}
                              />
                              <label htmlFor="option1">Take Away</label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                id="option2"
                                name="sortOption"
                                value="1"
                                defaultChecked={OrderType === 1}
                                onChange={handleOptionChange}
                              />
                              <label htmlFor="option2">Delivery</label>
                            </div>
                          </div>
                          <div className="cart-summary-footer">
                            {parseInt(customer_id) === 0 ? (
                              <button
                                data-target="#bd-example-modal"
                                data-toggle="modal"
                                className="btn btn-secondary btn-block btn-lg"
                              >
                                Place Order
                              </button>
                            ) : (
                              <button
                                className="btn btn-secondary btn-block btn-lg"
                                type="button"
                                onClick={() => handleNextStep()}
                              >
                                Place Order
                              </button>
                            )}
                          </div>
                          <div className="applypromo">
                            <p onClick={handleShow}>
                              Apply Coupon or Promocode
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />

      <Modal show={show} className="promoModal" centered onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Coupons & Promocodes</Modal.Title>
          <button
            type="button"
            className="closeButtonModal"
            onClick={handleClose}
          >
            <img src={Close} alt="close Img" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="promocodeslist">
            <ul>
              {promocodelist &&
                promocodelist.length > 0 &&
                promocodelist.map((item, index) => (
                  <li key={index}>
                    <div className="promoItem">
                      <h5>Use Code {item.promo_code}</h5>
                      <p className="promoDateail">{item.disc_msg}</p>
                      <div className="applyPromo">
                        <p>{item.promo_code}</p>
                        {PromocodeID === item.primary_id ? (
                          <button type="button">Selected</button>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>
                              handlepromocode_press(item.primary_id)
                            }
                          >
                            Apply
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CheckOut;
