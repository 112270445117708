import React, { useState, useEffect } from "react";
import {
  get_my_address_data_app,
  server_post_data,
  delete_address_data_app,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import { Modal, Button } from "react-bootstrap";
import nodatafound from "../Assests/images/no_data_found.png";
function MyAddress() {
  const [addresslist, setaddresslist] = useState([]);
  const [SelectedAddress, setSelectedAddress] = useState(0);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [DATANOTFOUND, setDATANOTFOUND] = useState(false);
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);

    await server_post_data(get_my_address_data_app, null)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.error) {
          handleError(Response.data.message);
        } else {
          setaddresslist(Response.data.message.data_address);
          if (Response.data.message.data_address.length > 0) {
            setDATANOTFOUND(false);
          } else {
            setDATANOTFOUND(true);
          }
        }
      })
      .catch((error) => {
        setDATANOTFOUND(true);
        setshowLoaderAdmin(false);
      });
  };

  const handleAddressChange = (address_id) => {
    setShowModal(true);
    setSelectedAddress(address_id);
  };

  const master_data_action_update = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("address_id", call_id);
    console.log(call_id);
    await server_post_data(delete_address_data_app, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get();
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const confirmVIP = (address_id) => {
    master_data_action_update(address_id);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="card card-body account-right">
      <div className={showLoaderAdmin ? "loading" : ""}></div>
      <div className="card-body">
        <div className="text-center">
          <ul>
            {addresslist &&
              addresslist.length > 0 &&
              addresslist.map((item, index) => (
                <li key={index}>
                  <div className="promoItem">
                    <h5 className="text-left">{item.type_address}</h5>
                    <p className="promoDateail text-left">
                      {item.building},{item.user_city},{item.user_state},
                      {item.user_country}
                    </p>
                    <div className="applyPromo applyPromo2 justify-content-end w-100">
                      <button
                        type="button"
                        onClick={() => handleAddressChange(item.primary_id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            {DATANOTFOUND && (
              <>
                <img src={nodatafound} alt="Card image cap" />
              </>
            )}
            <li>
              <div className="text-center">
                <a
                  href="#"
                  data-target="#bd-example-modal2"
                  data-toggle="modal"
                  className="btn btn-link"
                >
                  <i className="mdi mdi-account-circle" /> Add New Address
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>Are you sure you want to Delete Address ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(SelectedAddress)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyAddress;
